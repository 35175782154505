import { useQuery } from '@tanstack/react-query'

import { graphql } from '@/gql'
import useGraphQLClient from '@/utils/useAuthRequest'
import { GetMaterialsQuery } from '../../gql/graphql'

export const GET_MATERIALS_QUERY_KEY = 'materials'
const GET_MATERIALS = graphql(/* GraphQL */ `
  query GetMaterials {
    materials {
      id
      name
      itemCode
      unitOfMeasure
      laborCostPerUnit
      activeCostSchedule {
        id
        effectiveDate
        costPerUnit
        costPerContainer
        purchaseContainerLabel
        purchaseContainerUnitQuantity
      }
    }
  }
`)

type Material = GetMaterialsQuery['materials'][0]

const useMaterialsQuery = () => {
  const graphQLClient = useGraphQLClient()

  const { data, error, status } = useQuery({
    queryKey: [GET_MATERIALS_QUERY_KEY],
    queryFn: () => graphQLClient.request(GET_MATERIALS),
  })

  const materials = (data?.materials ?? []) as Material[]
  return { materials, error, status }
}

export default useMaterialsQuery
