import { createColumnHelper } from '@tanstack/react-table'
import { useAtomValue } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'

import CheckboxCell from '../components/CheckboxCell'
import DragHandleCell from '../components/DragHandleCell'
import EditNumberCell from '../components/EditNumberCell'
import IndicatorsCell from '../components/IndicatorsCell'
import MaterialSelectorCell from '../components/ItemCodeSelectorCell'
import MaterialReadOnlyCell from '../components/MaterialReadOnlyCell'
import NumberCell from '../components/NumberCell'
import SelectRowCell from '../components/SelectRowCell'
import SummaryNumberFooter from '../components/SummaryNumberFooter'
import TripCell from '../components/TripCell'
import WorkAreaCell from '../components/WorkAreaCell'
import WorkOrderSelectorCell from '../components/WorkOrderSelectorCell'

import Checkbox from '@atlaskit/checkbox'
import { columnOrderAtom, databaseEstimateWorkOrdersAtom } from '../editorAtoms'
import { COLUMN_WIDTH_DRAG, COLUMN_WIDTH_INDICATOR, COLUMN_WIDTH_SELECT } from '../editorConstants'
import { EditorColumnKey, QueriedEstimateWorkItem } from '../editorTypes'

const useHasWorkOrdersSelector = () => {
  const hasWorkOrdersAtom = useMemo(() => selectAtom(databaseEstimateWorkOrdersAtom, workOrders => workOrders.length > 0), [])
  return useAtomValue(hasWorkOrdersAtom)
}

const columnHelper = createColumnHelper<QueriedEstimateWorkItem>()

const defaultColumnDefs = {
  dragHandle: columnHelper.display({
    id: 'dragHandle',
    size: COLUMN_WIDTH_DRAG,
    enableResizing: false,
    cell: () => <DragHandleCell />,
    meta: { alwaysEditable: true },
  }),
  select: columnHelper.display({
    id: 'select',
    size: COLUMN_WIDTH_SELECT,
    enableResizing: false,
    header: ({ table }) => (
      <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Checkbox
          isChecked={table.getIsAllRowsSelected()}
          isIndeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()} //or getToggleAllPageRowsSelectedHandler
          size='large'
        />
      </div>
    ),
    meta: { alwaysEditable: true },
    cell: ({ row }) => <SelectRowCell row={row} />,
  }),
  indicators: columnHelper.display({
    id: 'indicators',
    size: COLUMN_WIDTH_INDICATOR,
    meta: { alwaysEditable: true },
    enableResizing: false,
    cell: ({ row }) => <IndicatorsCell row={row} />,
  }),
  workOrderId: columnHelper.accessor('workOrderId', {
    id: 'workOrderId',
    header: 'Work Order',
    meta: { canEdit: true, stringSelector: row => row?.workOrder?.name ?? null },
    enableSorting: true,
    sortingFn: (rowA, rowB) => {
      const workAreaA = rowA.original.workOrder
      const workAreaB = rowB.original.workOrder
      if (workAreaA && workAreaB) {
        return workAreaA.name.localeCompare(workAreaB.name)
      }
      return 0
    },
    cell: info => <WorkOrderSelectorCell workItemId={info.row.original.id} />,
  }),
  workAreaId: columnHelper.accessor('workAreaId', {
    id: 'workAreaId',
    header: 'Work Area',
    meta: { canEdit: true, stringSelector: row => row?.workArea?.name ?? null },
    enableSorting: true,
    sortingFn: (rowA, rowB) => {
      const workAreaA = rowA.original.workArea
      const workAreaB = rowB.original.workArea
      if (workAreaA && workAreaB) {
        return workAreaA.name.localeCompare(workAreaB.name)
      }
      return 0
    },
    cell: info => <WorkAreaCell workItemId={info.row.original.id} />,
  }),
  materialId: columnHelper.accessor('materialId', {
    id: 'materialId',
    header: 'Item Code',
    meta: { canEdit: true, stringSelector: row => row?.material?.itemCode ?? null, monospace: true },
    enableSorting: true,
    sortingFn: (rowA, rowB) => {
      const itemCodeA = rowA.original.material?.itemCode
      const itemCodeB = rowB.original.material?.itemCode
      if (itemCodeA && itemCodeB) {
        return itemCodeA.localeCompare(itemCodeB)
      }
      return 0
    },
    cell: info => <MaterialSelectorCell workItemId={info.row.original.id} />,
  }),
  materialName: columnHelper.accessor(row => row?.material?.name ?? null, {
    id: 'materialName',
    header: 'Material',
    meta: { stringSelector: row => row?.material?.name ?? null },
    enableSorting: true,
    sortingFn: (rowA, rowB) => {
      const materialNameA = rowA.original.material?.name
      const materialNameB = rowB.original.material?.name
      if (materialNameA && materialNameB) {
        return materialNameA.localeCompare(materialNameB)
      }
      return 0
    },
    cell: info => <MaterialReadOnlyCell workItemId={info.row.original.id} attribute='name' />,
  }),
  tripId: columnHelper.accessor('tripId', {
    id: 'tripId',
    header: 'Trip',
    meta: { canEdit: true, stringSelector: row => row?.trip?.name ?? null },
    enableSorting: true,
    sortingFn: (rowA, rowB) => {
      const tripA = rowA.original.trip
      const tripB = rowB.original.trip
      if (tripA && tripB) {
        return tripA.name.localeCompare(tripB.name)
      }
      return 0
    },
    cell: info => <TripCell workItemId={info.row.original.id} />,
  }),
  quantity: columnHelper.accessor('quantity', {
    id: 'quantity',
    header: 'Qty',
    meta: { canEdit: true, decimalPlaces: 2, monospace: true },
    cell: info => <EditNumberCell attribute='quantity' workItemId={info.row.original.id} />,
  }),
  unitOfMeasure: columnHelper.accessor(row => row?.material?.unitOfMeasure ?? null, {
    id: 'unitOfMeasure',
    header: 'Unit',
    meta: { stringSelector: row => row?.material?.unitOfMeasure ?? null },
    cell: info => <MaterialReadOnlyCell workItemId={info.row.original.id} attribute='unitOfMeasure' />,
  }),
  containersNeededFinal: columnHelper.accessor('containersNeededFinal', {
    id: 'containersNeededFinal',
    header: 'Needed',
    meta: { decimalPlaces: 2 },
    cell: info => <NumberCell value={info.getValue()} prefix='' readOnly />,
  }),
  costPerUnit: columnHelper.accessor(row => row?.materialCostSchedule?.costPerUnit ?? null, {
    id: 'costPerUnit',
    header: 'Cost/Unit',
    meta: { stringSelector: row => row?.materialCostSchedule?.costPerUnit ?? null, decimalPlaces: 4, prefix: '$' },
    cell: info => <MaterialReadOnlyCell workItemId={info.row.original.id} attribute='costPerUnit' />,
  }),
  materialCostCalculated: columnHelper.accessor('materialCostCalculated', {
    id: 'materialCostCalculated',
    header: 'Material Cost Total',
    meta: { decimalPlaces: 2, prefix: '$' },
    cell: info => <NumberCell value={info.getValue()} prefix='$' readOnly />,
    footer: () => <SummaryNumberFooter estimateAttribute='materialCostTotal' />,
  }),
  laborCostPerUnit: columnHelper.accessor(row => row?.material?.laborCostPerUnit ?? null, {
    id: 'laborCostPerUnit',
    header: 'Labor Cost/Unit',
    enableSorting: true,
    sortingFn: 'basic',
    meta: { stringSelector: row => row?.material?.laborCostPerUnit ?? null, decimalPlaces: 4, prefix: '$' },
    cell: info => <MaterialReadOnlyCell workItemId={info.row.original.id} attribute='laborCostPerUnit' />,
  }),
  laborCostFinal: columnHelper.accessor('laborCostFinal', {
    id: 'laborCostFinal',
    header: 'Labor Cost Total',
    enableSorting: true,
    sortingFn: 'basic',
    meta: { decimalPlaces: 2, prefix: '$' },
    cell: info => <NumberCell value={info.getValue()} prefix='$' readOnly />,
    footer: () => <SummaryNumberFooter estimateAttribute='laborCostTotal' />,
  }),
  totalCostCalculated: columnHelper.accessor('totalCostCalculated', {
    id: 'totalCostCalculated',
    header: 'Row Total Cost',
    enableSorting: true,
    sortingFn: 'basic',
    meta: { decimalPlaces: 2, prefix: '$' },
    cell: info => <NumberCell value={info.getValue()} prefix='$' readOnly />,
    footer: () => <SummaryNumberFooter estimateAttribute='totalCostTotal' />,
  }),
  marginPercent: columnHelper.accessor('marginPercent', {
    id: 'marginPercent',
    header: 'Margin %',
    enableSorting: true,
    sortingFn: 'basic',
    meta: { decimalPlaces: 2, suffix: '%' },
    cell: info => <NumberCell value={info.getValue()} suffix='%' readOnly />,
    footer: () => <SummaryNumberFooter estimateAttribute='marginPercent' suffix='%' prefix='' />,
  }),
  totalPriceOverride: columnHelper.accessor('totalPriceOverride', {
    id: 'totalPriceOverride',
    header: 'Override Total Price',
    enableSorting: true,
    sortingFn: 'basic',
    meta: { decimalPlaces: 2, prefix: '$', canEdit: true },
    cell: info => <EditNumberCell attribute='totalPriceOverride' workItemId={info.row.original.id} prefix='$' />,
  }),
  totalPriceFinal: columnHelper.accessor('totalPriceFinal', {
    id: 'totalPriceFinal',
    header: 'Row Total Price',
    enableSorting: true,
    sortingFn: 'basic',
    meta: { decimalPlaces: 2, prefix: '$' },
    cell: info => <NumberCell value={info.getValue()} prefix='$' readOnly />,
    footer: () => <SummaryNumberFooter estimateAttribute='totalPriceFinal' />,
  }),
  isVisibleOnQuote: columnHelper.accessor('isVisibleOnQuote', {
    id: 'isVisibleOnQuote',
    header: 'Show on Quote',
    enableSorting: true,
    sortingFn: 'basic',
    meta: { canEdit: true, alwaysEditable: true },
    cell: info => (
      <CheckboxCell
        workItemId={info.row.original.id}
        attribute='isVisibleOnQuote'
        isDisabled={info.row.original.workOrder?.isWorkCompleted ?? false}
      />
    ),
  }),
  isVisibleOnWorkOrder: columnHelper.accessor('isVisibleOnWorkOrder', {
    id: 'isVisibleOnWorkOrder',
    header: 'Show on WO',
    enableSorting: true,
    sortingFn: 'basic',
    meta: { canEdit: true, alwaysEditable: true },
    cell: info => (
      <CheckboxCell
        workItemId={info.row.original.id}
        attribute='isVisibleOnWorkOrder'
        isDisabled={info.row.original.workOrder?.isWorkCompleted ?? false}
      />
    ),
  }),
}

export const EDITABLE_COLUMN_IDS: EditorColumnKey[] = Object.keys(defaultColumnDefs)
  .filter(columnId => {
    const columnDef = defaultColumnDefs[columnId as EditorColumnKey]
    return !['dragHandle', 'select'].includes(columnId) && (columnDef.meta?.canEdit === true || columnDef.meta?.alwaysEditable === true)
  })
  .map(columnId => columnId as EditorColumnKey)

const useColumnDefs = () => {
  const columnOrder = useAtomValue(columnOrderAtom)
  const hasWorkOrders = useHasWorkOrdersSelector()
  console.log('hasWorkOrders: ', hasWorkOrders)
  const initialColumnOrder = useMemo(() => {
    if (hasWorkOrders) {
      if (columnOrder.includes('workOrderId')) {
        return columnOrder
      }
      const workOrderIndex = 2 // Insert at the third position (index 2)
      return [...columnOrder.slice(0, workOrderIndex), 'workOrderId', ...columnOrder.slice(workOrderIndex)]
    }
    return columnOrder
  }, [hasWorkOrders, columnOrder])

  const editableColumnIndexes = useMemo(() => {
    return initialColumnOrder.reduce<number[]>((acc, columnId, index) => {
      if (columnId in defaultColumnDefs && defaultColumnDefs[columnId as EditorColumnKey]?.meta?.canEdit === true) {
        acc.push(index)
      }
      return acc
    }, [])
  }, [initialColumnOrder])

  const columns = useMemo(() => {
    const columnDefs = initialColumnOrder.map((columnId, index) => {
      const columnDef = { ...defaultColumnDefs[columnId as EditorColumnKey] }
      if (columnDef.meta?.canEdit === true) {
        // check if index is first in array
        const editIndex = editableColumnIndexes.indexOf(index)
        if (editIndex === 0) {
          columnDef.meta = {
            ...(columnDef?.meta ?? {}),
            previousEditableIndex: editableColumnIndexes[editableColumnIndexes.length - 1],
            nextEditableIndex: editableColumnIndexes[1],
          }
        } else if (editIndex === editableColumnIndexes.length - 1) {
          columnDef.meta = {
            ...(columnDef?.meta ?? {}),
            previousEditableIndex: editableColumnIndexes[editableColumnIndexes.length - 2],
            nextEditableIndex: editableColumnIndexes[0],
          }
        } else {
          columnDef.meta = {
            ...(columnDef?.meta ?? {}),
            previousEditableIndex: editableColumnIndexes[editIndex - 1],
            nextEditableIndex: editableColumnIndexes[editIndex + 1],
          }
        }
      }
      return columnDef
    })

    return columnDefs
  }, [editableColumnIndexes, initialColumnOrder])

  return columns
}

export default useColumnDefs
