import { useRef } from 'react'
import styled from 'styled-components'

import { token } from '@atlaskit/tokens'

import useStaffListQuery from '@/utils/queryHooks/useStaffListQuery'

import HoveredDropZone from '@/components/HoveredDropZone'

import InstallerCard from './components/InstallerCard'

import useDropZoneSidebars from './hooks/useDropZoneSidebars'

import { DRAGGABLE_INSTALLER_CARD_GAP } from './dispatchConstants'
import { SidebarZone } from './dispatchTypes'

const zone: SidebarZone = 'INSTALLER_SIDEBAR'

const SidebarInstallers = () => {
  const { staffList, status, error: staffListError } = useStaffListQuery()
  const isLoading = status === 'pending'

  const sidebarRef = useRef<HTMLDivElement | null>(null)

  const isHovered = useDropZoneSidebars({ zone, sidebarRef })

  return (
    <StyledSidebarInstallers ref={sidebarRef}>
      <HoveredDropZone isHovered={isHovered} hoveredText='Drop to Unassign' hoveredTextLineTwo='Installer' inset={'56px 8px 8px 8px'} />

      <div className='sidebar-list-header'>
        <h4>Installers Available: {staffList.length} </h4>
      </div>

      <div className='sidebar-scrollable-list'>
        {isLoading && <div>Loading...</div>}

        {staffListError && <div>Error loading installer: {staffListError.message}</div>}

        {staffList.length === 0 ? (
          <div className='sidebar-none-found'>No installer found</div>
        ) : (
          staffList.map(staff => <InstallerCard key={staff.id} installerId={staff.id} />)
        )}
      </div>
    </StyledSidebarInstallers>
  )
}

export default SidebarInstallers

const StyledSidebarInstallers = styled.div`
  position: absolute;
  inset: 0;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;

  .sidebar-list-header {
    padding: 12px 32px 12px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid ${token('color.border')};
    h4 {
      font-weight: 500;
      color: ${token('color.text.subtle')};
    }
  }

  .sidebar-scrollable-list {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 1 auto;
    padding: 12px 32px 24px 24px;
    gap: ${DRAGGABLE_INSTALLER_CARD_GAP}px;
  }

  .sidebar-none-found {
    padding: 12px;
    font-size: 14px;
    font-weight: 500;
    color: ${token('color.text.subtlest')};
  }
`
