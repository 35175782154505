import { Temporal } from '@js-temporal/polyfill'
import { AnimatePresence, motion, Reorder } from 'framer-motion'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { useCallback, useEffect, useRef } from 'react'
import styled from 'styled-components'

import Button from '@atlaskit/button/new'
import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { token } from '@atlaskit/tokens'

import { MONTH_NAMES_LIST } from '@/utils/constants'

import useSelectedDateDispatches from '../hooks/useSelectedDateDispatches'

import { dateStringHoveredAtom, dateStringSelectedAtom, isWeeksExpandedAtom, workOrderSelectedAtom } from '../atoms'

import SchedulerCard from './SchedulerCard'

const MAX_ITEMS_VISIBLE = 4
const OVERFLOW_INDICATOR_HEIGHT = 28
const HEADER_PADDING = 62

interface CalendarDayCellProps {
  date: Temporal.PlainDate
  today: Temporal.PlainDate
}

const CalendarDayCell = ({ date, today }: CalendarDayCellProps) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const itemsContainerRef = useRef<HTMLDivElement | null>(null)

  const setHoveredDateString = useSetAtom(dateStringHoveredAtom)
  const setWorkOrderSelected = useSetAtom(workOrderSelectedAtom)
  const [dateStringSelected, setDateStringSelected] = useAtom(dateStringSelectedAtom)
  const isExpanded = useAtomValue(isWeeksExpandedAtom)

  const dispatches = useSelectedDateDispatches(date)
  const itemCount = dispatches.length
  const makeRoomFor = isExpanded ? itemCount : Math.min(itemCount, MAX_ITEMS_VISIBLE)
  const isOverflowing = itemCount > MAX_ITEMS_VISIBLE
  const height = makeRoomFor * HEADER_PADDING + (!isExpanded && itemCount > MAX_ITEMS_VISIBLE ? OVERFLOW_INDICATOR_HEIGHT : 0)

  const isToday = date.day === today.day && date.month === today.month && date.year === today.year
  const isPast = Temporal.PlainDate.compare(date, today) < 0
  const monthName = MONTH_NAMES_LIST[date.month - 1]

  const dateString = date.toString()
  const isSelected = dateStringSelected === dateString

  useEffect(() => {
    const el = ref.current
    if (!el) throw new Error('ref not set correctly')

    return dropTargetForElements({
      element: el,
      getData: () => ({ dateString }),
      onDragEnter: ({ source }) => {
        const sourceData = source.data
        console.log('sourceData', sourceData)
        setHoveredDateString(dateString)
      },
      onDragLeave: () => setHoveredDateString(prevValue => (prevValue === dateString ? null : prevValue)),
    })
  }, [dateString, setHoveredDateString])

  const handleSelectDay = useCallback(() => {
    if (isSelected) setDateStringSelected(null)
    else {
      setDateStringSelected(dateString)
      setWorkOrderSelected(null)
    }
  }, [isSelected, setDateStringSelected, dateString, setWorkOrderSelected])

  return (
    <DayCell ref={ref} className='eh-day-cell' $isPast={isPast} $isSelected={isSelected} $height={height} $isToday={isToday}>
      <div className='eh-day-cell-header'>
        <span className='eh-month-name'>{date.day === 1 ? monthName : date.dayOfWeek === 7 ? monthName.slice(0, 3) : ''}</span>

        <Button
          onClick={handleSelectDay}
          spacing='compact'
          appearance={isSelected ? 'primary' : 'subtle'}
          isSelected={!isSelected && isToday}
        >
          {date.day}
        </Button>
      </div>

      <div ref={itemsContainerRef} className='eh-items-container'>
        <Reorder.Group values={dispatches} onReorder={() => {}} as='div'>
          {dispatches.map(calendarDispatch => {
            const key = calendarDispatch?.workOrderId ?? '' + (calendarDispatch?.dispatchId ?? '')
            if (!calendarDispatch) return null
            return (
              <Reorder.Item key={key} value={calendarDispatch} as='div' transition={{ duration: 0.12 }}>
                <SchedulerCard calendarDispatch={calendarDispatch} dateString={calendarDispatch.dateString ?? dateString} />
              </Reorder.Item>
            )
          })}
        </Reorder.Group>
      </div>

      <AnimatePresence>
        {isOverflowing && !isExpanded ? (
          <OverflowDiv
            initial={{ opacity: 0, translateY: '100%' }}
            animate={{ opacity: 1, translateY: 0 }}
            exit={{ opacity: 0, translateY: '100%' }}
            transition={{
              duration: 0.15,
              enter: { ease: 'easeIn' },
              exit: { ease: 'easeOut' },
            }}
          >
            <span>+{dispatches.length - MAX_ITEMS_VISIBLE} Items</span>
          </OverflowDiv>
        ) : null}
      </AnimatePresence>
    </DayCell>
  )
}

export default CalendarDayCell

const OverflowDiv = styled(motion.div)`
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: ${token('space.050')};
  box-shadow: ${token('elevation.shadow.overflow')};
  align-items: center;
  justify-content: center;
  z-index: 1;
  background: ${token('elevation.surface.sunken')};

  font-size: 11px;
  font-weight: 600;
  color: ${token('color.text.subtlest')};
`
interface IsTodayProps {
  $isToday: boolean
  $isPast?: boolean
  $isSelected?: boolean
  $height: number
}
const DayCell = styled.div<IsTodayProps>`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  align-items: center;
  padding: 0 ${token('space.100')};

  background: ${({ $isPast, $isSelected }) =>
    $isSelected ? token('color.background.selected') : $isPast ? token('elevation.surface.sunken') : token('elevation.surface')};

  overflow: hidden;

  transition: background 0.08s;

  .eh-items-container {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    width: 100%;
    border-radius: 4px;
    font-size: 12px;
    padding-top: ${token('space.500')};

    min-height: 100px;
    height: ${({ $height }) => $height}px;

    transition: height 150ms;
  }

  .eh-day-cell-header {
    font-size: 20px;
    z-index: 2;

    font-weight: ${({ $isToday }) => ($isToday ? '700' : '500')};
    position: absolute;
    top: 0px;
    right: 0px;
    left: ${token('space.050')};
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    color: ${({ $isToday, $isPast }) =>
      $isToday ? token('color.text.brand') : $isPast ? token('color.text.disabled') : token('color.text.subtlest')};
    > button {
      padding-right: 8px;
      padding-left: 8px;
      transition: background-color 120ms;
    }

    .eh-month-name {
      flex: 1;
      flex-shrink: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-top: 2px;
      color: ${({ $isToday }) => ($isToday ? token('color.text.brand') : token('color.text.disabled'))};
    }
  }
`
