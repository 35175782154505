import { atom } from 'jotai'

import { GetDispatchDateQuery, SubmitDispatchAssignmentMutationVariables } from '@/gql/graphql'

export type Dispatch = GetDispatchDateQuery['dispatches'][0] & { dateString?: string }

export type IdListById = {
  [key: string]: string[]
} & {
  dateString?: string
}

export type ViewMode = 'by-dispatch' | 'by-installer' | 'by-vehicle'
export type SidebarZone = 'INSTALLER_SIDEBAR' | 'DISPATCH_SIDEBAR'
export type DispatchDropZone = 'VEHICLE_HEADER' | 'VEHICLE_BODY' | 'VEHICLE_DISPATCH_INSTALLERS' | SidebarZone

export type ItemIdentifiedVariant = 'INSTALLER' | 'DISPATCH'
export type DragSourceData = {
  installerId?: string | null
  dispatchId?: string | null
  vehicleId?: string | null
  vehicleDispatchId?: string | null
  assignedVehicleDispatchIds?: string[] | null
  assignedVehicleIds?: string[] | null
  itemIdentifiedVariant?: ItemIdentifiedVariant
}

export interface DragDestinationData {
  zone?: DispatchDropZone
  vehicleId?: string | null
  vehicleDispatchId?: string | null
  dispatchId?: string | null
}
export type VehicleDispatch = { dateString?: string } & GetDispatchDateQuery['vehicleDispatchAssignments'][0]

export const dispatchIdsByTripAtom = atom<IdListById>({})

// indexed by vehicle dispatch id
export const installerIdsByVehicleDispatchIdAtom = atom<IdListById>({})
export const vehicleDispatchesByIdAtom = atom<Record<string, VehicleDispatch>>({})

// indexed by vehicle id
export const vehicleDispatchIdsByVehicleIdAtom = atom<IdListById>({})
export const dispatchIdsByVehicleIdAtom = atom<IdListById>({})
export const sortedVehicleDispatchIdsByVehicleIdAtom = atom<IdListById>({})

// indexed by installer id
export const assignedVehicleDispatchIdsByInstallerIdAtom = atom<IdListById>({})

// indexed by dispatch id
export const dispatchesByIdAtom = atom<Record<string, Dispatch>>({})

export const itemPickedUpAtom = atom<DragSourceData>({})
export const itemMouseOverAtom = atom<DragSourceData>({})

export const DEFAULT_HOVERED_DROP_ZONE_STATE: DragDestinationData = {}
export const hoveredDropZoneAtom = atom<DragDestinationData>(DEFAULT_HOVERED_DROP_ZONE_STATE)

export const isSavingAssignmentAtom = atom<boolean>(false)

export const centerSectionWidthAtom = atom<number | null>(null)

export const pendingMutationsAtom = atom<Partial<SubmitDispatchAssignmentMutationVariables>>({})
