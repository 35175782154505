import { useQuery } from '@tanstack/react-query'
import type { TableProps } from 'antd'
import { ConfigProvider, Table } from 'antd'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import logo from '@/assets/Logo96x96.png'

import { MONOSPACE_FONT_FAMILY } from '@/utils/constants'
import useAntdTableTheme from '@/utils/useAntdTableTheme'
import useGraphQLClient from '@/utils/useAuthRequest'

import { graphql } from '@/gql'
import { GetWorkOrderAndUserQuery } from '@/gql/graphql'
import { token } from '@atlaskit/tokens'

type WorkOrderType = GetWorkOrderAndUserQuery['workOrder']
type WorkItemType = WorkOrderType['workItems'][0]
type MaterialType = WorkItemType['material']
type ByMaterialType = {
  totalNeeded: number | string
  totalQuantity: number | string
  material: MaterialType
  purchaseContainerLabel: string
  workItems: WorkItemType[]
}

type LoadSheetItem = {
  workArea: string
  itemCode: string
  materialName: string
  needed: number | string
  quantity: number | string
  unitOfMeasure: string
  purchaseContainerLabel: string
  used: string
}
const WorkOrderView = () => {
  const params = useParams()
  const workOrderId = params?.workOrderId ?? ''
  const dateString = params?.dateString ?? null
  const graphqlClient = useGraphQLClient()
  const theme = useAntdTableTheme(true)

  const { data, status } = useQuery({
    queryKey: [GET_WORK_ORDER_AND_USER_QUERY_KEY, workOrderId],
    queryFn: async () => graphqlClient.request(GET_WORK_ORDER_AND_USER, { workOrderId }),
  })
  const workOrder = data?.workOrder ?? null
  const items = (workOrder?.workItems ?? [])
    .filter((item: WorkItemType) => item.isVisibleOnWorkOrder)
    .sort((a: WorkItemType, b: WorkItemType) => (a.workArea?.name ?? '').localeCompare(b.workArea?.name ?? ''))

  const notesForPrint = workOrder?.estimate?.job?.notesForPrint ?? ''
  const workOrderNotes = workOrder?.estimate?.job?.customer?.workOrderNotes ?? ''
  const loadSheetItems: LoadSheetItem[] = []
  const byMaterial = items.reduce(
    (acc: Record<string, ByMaterialType>, item: WorkItemType) => {
      if (item.material && item.material.itemCode) {
        const itemCode = item.material.itemCode

        if (!acc[itemCode]) {
          // itemCode doesn't exist yet, so create new object
          const newMaterialItem: ByMaterialType = {
            totalNeeded: Number(item.containersNeededFinal ?? 0),
            material: item.material,
            purchaseContainerLabel: item.materialCostSchedule?.purchaseContainerLabel ?? '',
            workItems: [item],
            totalQuantity: Number(item.quantity ?? 0),
          }
          acc[itemCode] = newMaterialItem
        } else {
          acc[itemCode].workItems = [...acc[itemCode].workItems, item] as WorkItemType[]
          acc[itemCode].totalNeeded = Number(acc[itemCode].totalNeeded) + Number(item.containersNeededFinal ?? 0)
          acc[itemCode].totalQuantity = Number(acc[itemCode].totalQuantity) + Number(item.quantity ?? 0)
        }
        acc[itemCode].totalNeeded = Number(acc[itemCode].totalNeeded).toFixed(2)
        acc[itemCode].totalQuantity = Number(acc[itemCode].totalQuantity).toFixed(2)
      }
      return acc
    },
    {} as Record<string, ByMaterialType>
  )

  Object.keys(byMaterial).forEach((itemCode: string) => {
    const materialItems = byMaterial[itemCode]
    const firstItem = {
      workArea: 'Combined',
      itemCode,
      materialName: (materialItems.material?.name ?? '') + ' TOTAL',
      quantity: materialItems.totalQuantity,
      unitOfMeasure: materialItems.material?.unitOfMeasure ?? '',
      needed: materialItems.totalNeeded,
      purchaseContainerLabel: materialItems.purchaseContainerLabel,
      used: '',
    }
    if (materialItems.workItems.length === 1) {
      const workItem = materialItems.workItems[0]
      firstItem.materialName = workItem.material?.name ?? '-'
      firstItem.workArea = workItem.workArea?.name ?? '-'
      firstItem.quantity = Number(workItem.quantity ?? 0).toFixed(2)
      firstItem.needed = Number(workItem.containersNeededFinal ?? 0).toFixed(2)
      firstItem.unitOfMeasure = workItem.material?.unitOfMeasure ?? ''
    }
    loadSheetItems.push(firstItem)
    if (materialItems.workItems.length > 1) {
      materialItems.workItems.forEach((workItem: WorkItemType) => {
        loadSheetItems.push({
          workArea: workItem.workArea?.name ?? '-',
          itemCode: '',
          materialName: '',
          quantity: Number(workItem.quantity ?? 0).toFixed(2),
          unitOfMeasure: workItem.material?.unitOfMeasure ?? '-',
          needed: Number(workItem.containersNeededFinal ?? 0).toFixed(2),
          purchaseContainerLabel: workItem.materialCostSchedule?.purchaseContainerLabel ?? '-',
          used: '',
        })
      })
    }
  })

  return (
    <ConfigProvider theme={theme}>
      <title>
        Work Order #{workOrder?.id}-{workOrder?.name} - Thermal Shop Admin
      </title>
      <PageWrapper>
        <PrintableWrapper>
          <HeaderRow>
            <ThermalShopInfo>
              <img src={logo} alt='Thermal Shop Logo' />
              <WorkOrderTitleText>
                Work Order #
                <span className='mono'>
                  {workOrder?.id}-{workOrder?.name}
                </span>
              </WorkOrderTitleText>
            </ThermalShopInfo>

            <PrintInfo>
              <AttributeColumn>
                {dateString === null ? null : <Attribute>Dispatch Date</Attribute>}
                {/* <Attribute>Salesman</Attribute> */}
              </AttributeColumn>

              <ValuesColumn>
                {dateString === null ? null : <Value>{dateString}</Value>}
                {/* <Value>-</Value> */}
              </ValuesColumn>
            </PrintInfo>
          </HeaderRow>

          <SubheaderRow>
            <SubheaderColumn>
              <SubheaderTitle>Job Site Address</SubheaderTitle>
              <SubheaderText>{workOrder?.estimate?.job?.projectSite?.name ?? '-'}</SubheaderText>
            </SubheaderColumn>

            <SubheaderColumn>
              <SubheaderTitle>Project</SubheaderTitle>
              <SubheaderText>{workOrder?.estimate?.title ?? '-'}</SubheaderText>
            </SubheaderColumn>

            <SubheaderColumn>
              <SubheaderTitle>Customer</SubheaderTitle>
              <SubheaderText>{workOrder?.estimate?.job?.customer?.name ?? '-'}</SubheaderText>
            </SubheaderColumn>
          </SubheaderRow>

          {notesForPrint !== '' ? (
            <NotesRow>
              <SubheaderTitle>Job Notes</SubheaderTitle>
              <NotesText>{notesForPrint}</NotesText>
            </NotesRow>
          ) : null}

          {workOrderNotes !== '' ? (
            <NotesRow>
              <SubheaderTitle>Builder Notes</SubheaderTitle>
              <NotesText>{workOrderNotes}</NotesText>
            </NotesRow>
          ) : null}

          <Table<LoadSheetItem>
            rowKey='id'
            columns={loadSheetColumns}
            dataSource={loadSheetItems}
            size='small'
            loading={status === 'pending'}
            pagination={false}
            rowClassName={(item, index) => {
              let className = index % 2 === 1 ? 'alt-row-style' : 'main-row-style'
              if (item.materialName.toLowerCase().includes('total')) {
                className = className + ' total-row-style'
              }
              return className
            }}
          />
        </PrintableWrapper>
      </PageWrapper>
    </ConfigProvider>
  )
}

export default WorkOrderView

const loadSheetColumns: TableProps<LoadSheetItem>['columns'] = [
  {
    key: 'materialName',
    dataIndex: 'materialName',
    title: 'Material',
  },
  {
    key: 'workArea',
    dataIndex: 'workArea',
    title: 'Work Area',
  },
  {
    key: 'quantity',
    dataIndex: 'quantity',
    title: 'Qty',
    align: 'right',
  },
  {
    key: 'unitOfMeasure',
    dataIndex: 'unitOfMeasure',
    title: 'Unit',
  },
  {
    key: 'needed',
    dataIndex: 'needed',
    title: 'Needed',
    align: 'right',
  },
  {
    key: 'purchaseContainerLabel',
    dataIndex: 'purchaseContainerLabel',
    title: 'Container',
  },
  {
    key: 'loaded',
    title: 'Loaded',
    width: 80,
    align: 'right',
    className: 'border-column',
  },
  {
    key: 'used',
    title: 'Used',
    width: 80,
    align: 'right',
    className: 'border-column',
  },
  {
    key: 'returned',
    title: 'Returned',
    width: 80,
    align: 'right',
    className: 'border-column',
  },
  {
    key: 'actual',
    title: 'Actual',
    width: 80,
    align: 'right',
    className: 'border-column',
  },
]

const GET_WORK_ORDER_AND_USER_QUERY_KEY = 'workOrderByIdNum'
const GET_WORK_ORDER_AND_USER = graphql(/* GraphQL */ `
  query GetWorkOrderAndUser($workOrderId: ID!) {
    currentUser {
      id
      fullName
      firstName
      lastName
      email
    }
    workOrder(pk: $workOrderId) {
      id
      name
      status
      deferScheduleDate
      statusNotes
      materialCostTotal
      laborCostTotal
      totalCostTotal
      totalPrice
      marginPercent
      nextDispatchDate
      isConfirmed
      isWorkCompleted
      dispatches {
        id
        date
      }
      estimateId
      estimate {
        title
        job {
          title
          notesForPrint
          customerId
          customer {
            name
            workOrderNotes
          }
          projectSiteId
          projectSite {
            name
            placeId
            place {
              displayName
              formattedAddress
            }
          }
        }
      }
      workItems {
        estimateId
        id
        quantity
        containersNeededFinal
        isVisibleOnQuote
        isVisibleOnWorkOrder
        workAreaId
        workArea {
          name
        }
        tripId
        trip {
          name
        }
        materialId
        materialCostScheduleId
        materialCostSchedule {
          purchaseContainerLabel
        }
        material {
          id
          name
          itemCode
          unitOfMeasure
        }
      }
    }
  }
`)

const NotesRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  padding: 12px 8px 24px 8px;
`
const NotesText = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin: 0;
`
const SubheaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 12px 0;
`
const SubheaderColumn = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  min-width: 200px;
  padding: 0 8px;
`
const SubheaderTitle = styled.p`
  font-weight: 600;
  margin: 0;
`
const SubheaderText = styled.p`
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  min-height: 50px;
`

const ValuesColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`
const Value = styled.div`
  font-size: 12px;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 8px;
`
const ThermalShopInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50%;
  > img {
    height: 72px;
    width: 72px;
    margin-right: 8px;
  }
  .mono {
    font-family: ${MONOSPACE_FONT_FAMILY};
  }
`
const WorkOrderTitleText = styled.h2`
  font-weight: 400;
  margin: 0;
  color: #000;
`
const PrintInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 40px;
`
const AttributeColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  margin-right: 12px;
`
const Attribute = styled.div`
  font-size: 12px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  position: relative;
  color: #000;
  background-color: #fff;
  overflow: auto;
`
const PrintableWrapper = styled.div`
  flex: 1;
  /* border: 3px solid blue; */
  display: flex;
  flex-direction: column;
  align-items: stretch;

  position: relative;
  padding: 24px;
  max-width: 800px;
  background-color: #fff;

  .ant-table-cell {
    color: #000;
    padding-top: 4px;
    padding-bottom: 4px;
    /* text-overflow: ellipsis; */
    overflow: hidden;
    white-space: nowrap;
  }
  .alt-row-style {
    background-color: #f0f0f0;
  }
  .main-row-style {
    background-color: #fff;
  }
  .total-row-style {
    font-weight: 700;
  }
  td.border-column {
    border-right: 1px solid ${token('color.border')};
  }
`
