import styled from 'styled-components'

import Link from '@atlaskit/link'
import { token } from '@atlaskit/tokens'
import { TooltipPrimitive, TooltipPrimitiveProps } from '@atlaskit/tooltip'

import { CalendarWorkOrderType } from '../types'

interface SchedulerCardHoverProps extends TooltipPrimitiveProps {
  calendarDispatch: CalendarWorkOrderType
  accentColor: string
  dateString?: string
}

const SchedulerCardHover = ({ calendarDispatch, accentColor, ...rest }: SchedulerCardHoverProps) => {
  const jobId = calendarDispatch?.workOrder?.jobId ?? null
  const estimateId = calendarDispatch?.workOrder?.estimateId ?? null
  return (
    <TooltipCard $accentColor={accentColor} {...rest}>
      <div className='erh-tooltip-customer-section'>
        <div className='erh-tripName'>{calendarDispatch?.workOrder?.name ?? '-'}</div>

        <div className='erh-customerName'>{calendarDispatch?.workOrder?.customerName ?? 'No Customer Name'}</div>
      </div>

      <div className='erh-tooltip-address-section'>
        <div className='erh-address'>{calendarDispatch?.workOrder?.projectSiteAddress ?? 'No Job Site Address'}</div>

        <Link href={`/jobs/${jobId}/estimates/${estimateId}`} target='_blank'>
          {calendarDispatch?.estimateTitle ?? 'Link to Estimate'}
        </Link>
      </div>
    </TooltipCard>
  )
}

export default SchedulerCardHover

interface TooltipCardProps extends TooltipPrimitiveProps {
  $accentColor: string
}
// eslint-disable-next-line @atlaskit/design-system/no-unsafe-style-overrides
const TooltipCard = styled(TooltipPrimitive)<TooltipCardProps>`
  background: ${({ $accentColor }) =>
    token(
      // @ts-expect-error known issue with token function
      !$accentColor ? 'elevation.surface.raised' : `color.background.accent.${$accentColor}.subtler`
    )};
  display: flex;
  flex-direction: column;
  box-shadow: ${token('elevation.shadow.overlay')};
  padding: 0px 4px;
  position: relative;
  color: ${token('color.text')};
  border: 2px solid
    ${({ $accentColor }) =>
      !$accentColor
        ? 'transparent'
        : token(
            // @ts-expect-error known issue with token function
            `color.border.accent.${$accentColor}`
          )};

  border-radius: 4px;
  overflow: hidden;
  min-width: 160px;

  .erh-tooltip-customer-section {
    display: flex;
    flex-direction: column;

    padding: 4px 2px;
    border-bottom: 1px solid ${token('color.border')};
    .erh-customerName {
      font-weight: bold;
      font-size: 13px;
    }
    .erh-tripName {
      font-size: 11px;
      color: ${({ $accentColor }) =>
        // @ts-expect-error known issue with token function
        !$accentColor ? token(`color.text.sublest`) : token(`color.text.accent.${$accentColor}`)};
      text-transform: uppercase;
      font-weight: 600;
    }
    /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
  }

  .erh-tooltip-address-section {
    display: flex;
    flex-direction: column;
    font-size: 11px;
    padding: 4px 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .erh-address {
      margin-bottom: 4px;
    }
  }
`
