import { useSetAtom } from 'jotai'
import { useCallback, useEffect, useMemo } from 'react'

import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'

import { DispatchDropZone, DragDestinationData, hoveredDropZoneAtom } from '../dispatchTypes'

interface UseDropZoneVehicleContainerProps {
  vehicleId: string
  vehicleDispatchId: string
  containerRef: React.RefObject<HTMLDivElement | null>
}

const zone: DispatchDropZone = 'VEHICLE_DISPATCH_INSTALLERS'

const useDropZoneVehicleInstallers = ({ vehicleId, vehicleDispatchId, containerRef }: UseDropZoneVehicleContainerProps) => {
  const dropZoneData: DragDestinationData = useMemo(() => ({ vehicleId, vehicleDispatchId, zone }), [vehicleId, vehicleDispatchId])
  const setItemHovered = useSetAtom(hoveredDropZoneAtom)

  const onDragLeave = useCallback(
    () => setItemHovered(prev => (prev.vehicleDispatchId !== vehicleDispatchId ? prev : {})),
    [vehicleDispatchId, setItemHovered]
  )
  const onDragEnter = useCallback(() => setItemHovered(dropZoneData), [dropZoneData, setItemHovered])

  useEffect(() => {
    const dropTargetElement = containerRef.current
    if (!dropTargetElement) throw new Error('ref not set correctly')

    return dropTargetForElements({
      element: dropTargetElement,
      getData: () => ({ ...dropZoneData }),
      onDragEnter,
      onDragLeave,
      canDrop: ({ source }) => !!source.data?.installerId,
    })
  }, [dropZoneData, onDragEnter, onDragLeave, containerRef])
}

export default useDropZoneVehicleInstallers
