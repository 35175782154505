import { useSetAtom } from 'jotai'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import styled from 'styled-components'

import CheckCircleIcon from '@atlaskit/icon/glyph/check-circle'
import { draggable } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { token } from '@atlaskit/tokens'
import Tooltip from '@atlaskit/tooltip'

import { CardColorOptions, TRIP_COLORS } from '@/utils/utilities'

import useIsDragging from '../hooks/useIsDragging'
import useSelectedDispatch from '../hooks/useSelectedDispatch'

import { DragSourceData, itemPickedUpAtom } from '../dispatchTypes'

import { DRAGGABLE_DISPATCH_CARD_WIDTH } from '../dispatchConstants'
import DraggableDispatchCardHover from './DraggableDispatchCardHover'

interface DraggableDispatchCardProps {
  dispatchId: string
  vehicleId?: string | null
}

const DraggableDispatchCard = ({ dispatchId, vehicleId = null }: DraggableDispatchCardProps) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const setItemPickedUp = useSetAtom(itemPickedUpAtom)

  const dispatch = useSelectedDispatch(dispatchId)
  const assignedVehicleIds = (dispatch?.vehicleAssignments ?? []).map(va => va.vehicleId)
  const vehicleDispatchCount = assignedVehicleIds.length
  const isWorkCompleted = dispatch?.workOrder?.isWorkCompleted ?? false
  const tripName = dispatch?.workOrder?.tripName ?? 'None'
  const accentColor: CardColorOptions = TRIP_COLORS?.[tripName] ?? 'orange'

  const draggableItem: DragSourceData = useMemo(
    () => ({
      dispatchId,
      vehicleId,
      assignedVehicleIds,
      itemIdentifiedVariant: 'DISPATCH',
    }),
    [dispatchId, vehicleId, assignedVehicleIds]
  )
  const getInitialData = useCallback(() => draggableItem, [draggableItem])
  const onDragStart = useCallback(() => setItemPickedUp(draggableItem), [draggableItem, setItemPickedUp])
  const onDrop = useCallback(() => setItemPickedUp(prev => (prev.dispatchId !== dispatchId ? prev : {})), [dispatchId, setItemPickedUp])

  useEffect(() => {
    if (ref.current) return draggable({ element: ref.current, getInitialData, onDragStart, onDrop })
  }, [getInitialData, onDragStart, onDrop])

  const { isDragging } = useIsDragging(draggableItem)

  return (
    <OuterCardContainer
      ref={ref}
      $accentColor={accentColor}
      $isDragging={isDragging}
      $isConfirmed={Boolean(dispatch?.workOrder?.isConfirmed)}
      $isWorkCompleted={isWorkCompleted}
    >
      <Tooltip
        component={props => !!dispatch && <DraggableDispatchCardHover dispatch={dispatch} accentColor={accentColor} {...props} />}
        content='Click to select'
        position='auto-start'
      >
        {({ ref: tooltipRef, ...tooltipProps }) => (
          <div ref={tooltipRef} className='erh-inner-card-container' {...tooltipProps}>
            {isWorkCompleted && (
              <div className='erh-work-completed-section'>
                <CheckCircleIcon
                  label='Work Completed'
                  size='large'
                  primaryColor={token('color.background.accent.green.subtler.pressed')}
                  secondaryColor={token('color.text.accent.green.bolder')}
                />
              </div>
            )}

            {vehicleDispatchCount > 0 && <div className='dispatch-count-badge'>{vehicleDispatchCount}</div>}

            <div className='erh-customer-section'>{dispatch?.workOrder?.customerName ?? '-'}</div>
            <div className='erh-address-section'>{dispatch?.workOrder?.projectSiteAddress ?? '-'}</div>
          </div>
        )}
      </Tooltip>
    </OuterCardContainer>
  )
}

export default DraggableDispatchCard

interface CardContainerProps {
  $accentColor: CardColorOptions
  $isDragging: boolean
  $isConfirmed?: boolean
  $isWorkCompleted?: boolean
}

const OuterCardContainer = styled.div<CardContainerProps>`
  /* box-shadow: ${token('elevation.shadow.raised')}; */
  position: relative;

  box-sizing: border-box;

  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 6px;
  padding: 0;
  width: ${DRAGGABLE_DISPATCH_CARD_WIDTH}px;

  transition:
    box-shadow 0.08s,
    opacity 0.06s;

  opacity: ${({ $isDragging }) => ($isDragging ? '0.5' : '1')};
  &:hover {
    box-shadow: ${token('elevation.shadow.raised')};
    cursor: ${({ $isWorkCompleted }) => ($isWorkCompleted ? 'pointer' : 'grab')};
  }

  .erh-inner-card-container {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 0px 4px;
    height: 52px;

    border-radius: 6px;
    border: 1px solid ${({ $accentColor }) => token(!$accentColor ? `color.border` : `color.border.accent.${$accentColor}`)};
    background: ${({ $accentColor }) =>
      token(!$accentColor ? `elevation.surface.raised` : `color.background.accent.${$accentColor}.subtlest`)};

    color: ${token('color.text')};

    transition: background 0.08s;

    &:hover {
      background: ${({ $accentColor }) =>
        token(!$accentColor ? `elevation.surface.raised` : `color.background.accent.${$accentColor}.subtle.hovered`)};

      .dispatch-count-badge {
        border: 8px solid
          ${({ $accentColor }) =>
            token(!$accentColor ? `elevation.surface.raised` : `color.background.accent.${$accentColor}.subtle.hovered`)};
      }
    }
  }

  .erh-customer-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    padding: 4px 2px;
    border-bottom: 1px solid ${token('color.border')};

    font-weight: bold;
    font-size: 13px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .erh-address-section {
    display: -webkit-box;
    padding: 4px 2px;
    -webkit-box-orient: vertical;

    font-size: 11px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .erh-work-completed-section {
    background: ${token('color.blanket')};
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    span {
      /* border: 3px solid red; */
      border-radius: 50%;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
      svg > g {
        transform: scale(1.2);
        transform-origin: center;
      }
    }
  }

  .dispatch-count-badge {
    position: absolute;
    right: 4px;
    top: 8px;
    font-size: 11px;
    background: ${token('color.background.accent.gray.bolder')};
    color: ${token('color.text.inverse')};
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 8px solid
      ${({ $accentColor }) => token(!$accentColor ? `elevation.surface.raised` : `color.background.accent.${$accentColor}.subtlest`)};
    transition: border 0.08s;
  }
`
