import styled from 'styled-components'

import Link from '@atlaskit/link'
import { token } from '@atlaskit/tokens'

import DragHandlerIcon from '@atlaskit/icon/glyph/drag-handler'

import { CardColorOptions, TRIP_COLORS } from '@/utils/utilities'

import useSelectedDispatch from '../hooks/useSelectedDispatch'
import useSelectedVehicleDispatch from '../hooks/useSelectedVehicleDispatch'

import { VEHICLE_DISPATCH_CARD_HEIGHT, VEHICLE_DISPATCH_LEFT_COL_WIDTH } from '../dispatchConstants'
import VehicleDispatchInstallerSection from './VehicleDispatchInstallerSection'

import { useSetAtom } from 'jotai'
import { useCallback, useEffect, useMemo, useRef } from 'react'

import { draggable } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'

import useIsDragging from '../hooks/useIsDragging'

import { DragSourceData, itemPickedUpAtom } from '../dispatchTypes'

interface VehicleDispatchContainerProps {
  vehicleDispatchId: string
  dateString: string
  vehicleId: string
}

const VehicleDispatchContainer = ({ vehicleDispatchId, dateString, vehicleId }: VehicleDispatchContainerProps) => {
  const vehicleDispatch = useSelectedVehicleDispatch(vehicleDispatchId, dateString)
  const dispatchId = vehicleDispatch?.dispatchId ?? null
  const dispatch = useSelectedDispatch(dispatchId)
  const assignedVehicleIds = (dispatch?.vehicleAssignments ?? []).map(va => va.vehicleId)
  const setItemPickedUp = useSetAtom(itemPickedUpAtom)
  const ref = useRef<HTMLDivElement | null>(null)

  const installerAssignmentsIds = (vehicleDispatch?.installerAssignments ?? []).map(installer => installer.installerId)

  const workOrder = dispatch?.workOrder ?? null
  const tripName = workOrder?.tripName ?? 'No Trip Name'

  const accentColor: CardColorOptions = TRIP_COLORS?.[tripName] ?? 'orange'

  const address = workOrder?.projectSiteAddress ?? 'No Job Site Address'

  const draggableItem: DragSourceData = useMemo(
    () => ({
      dispatchId,
      vehicleId,
      assignedVehicleIds,
      itemIdentifiedVariant: 'DISPATCH',
    }),
    [dispatchId, vehicleId, assignedVehicleIds]
  )
  const getInitialData = useCallback(() => draggableItem, [draggableItem])
  const onDragStart = useCallback(() => setItemPickedUp(draggableItem), [draggableItem, setItemPickedUp])
  const onDrop = useCallback(() => setItemPickedUp(prev => (prev.dispatchId !== dispatchId ? prev : {})), [dispatchId, setItemPickedUp])

  useEffect(() => {
    if (ref.current) return draggable({ element: ref.current, getInitialData, onDragStart, onDrop })
  }, [getInitialData, onDragStart, onDrop])

  const { isDragging } = useIsDragging(draggableItem)

  return (
    <StyledVehicleDispatchContainer $accentColor={accentColor} $isDragging={isDragging}>
      <div className='vehicle-dispatch-left-column' ref={ref}>
        <div className='drag-handle'>
          <DragHandlerIcon label='Drag' size='medium' />
        </div>

        <div className='job-card-info'>
          <div className='customer-name'>{workOrder?.customerName ?? 'No Customer Name'}</div>

          <div className='job-site-address'>
            <Link appearance='subtle' href={`/jobs/${workOrder?.jobId}/estimates/${workOrder?.estimateId}`} target='_blank'>
              {address}
            </Link>
          </div>
        </div>
      </div>

      <VehicleDispatchInstallerSection
        installerAssignmentsIds={installerAssignmentsIds}
        vehicleDispatchId={vehicleDispatchId}
        vehicleId={vehicleId}
      />
    </StyledVehicleDispatchContainer>
  )
}

export default VehicleDispatchContainer

interface StyledVehicleDispatchContainerProps {
  $accentColor: CardColorOptions
  $isDragging: boolean
}
const StyledVehicleDispatchContainer = styled.div<StyledVehicleDispatchContainerProps>`
  position: relative;
  overflow: hidden;

  display: flex;
  flex-direction: row;
  align-items: stretch;

  flex: 1 1 auto;

  height: ${VEHICLE_DISPATCH_CARD_HEIGHT}px;
  box-sizing: border-box;
  /* margin-bottom: 8px; */

  border-radius: 6px;
  border: 1px solid ${({ $accentColor }) => token(!$accentColor ? `color.border` : `color.border.accent.${$accentColor}`)};
  background: ${token('elevation.surface.raised')};

  .vehicle-dispatch-left-column {
    border: 0px solid ${({ $accentColor }) => token(!$accentColor ? `color.border` : `color.border.accent.${$accentColor}`)};
    border-right-width: 1px;
    border-left-width: 3px;
    background: ${({ $accentColor }) =>
      token(!$accentColor ? `elevation.surface.raised` : `color.background.accent.${$accentColor}.subtlest`)};

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex: 0 0 none;

    width: ${VEHICLE_DISPATCH_LEFT_COL_WIDTH}px;
    min-width: ${VEHICLE_DISPATCH_LEFT_COL_WIDTH}px;
    overflow: hidden;

    > div {
      opacity: ${({ $isDragging }) => ($isDragging ? `0.5` : `1`)};
    }

    .drag-handle {
      margin: 0 4px;
      border-radius: 4px;
      opacity: 0.5;

      display: flex;
      justify-content: center;
      align-items: center;

      height: 40px;
      width: 36px;

      flex: 0 0 auto;

      &:hover {
        cursor: grab;
        opacity: 1;
        background: ${token('color.background.neutral.subtle.hovered')};
      }
    }

    .job-card-info {
      flex: 1 1 auto;
      padding: 4px 8px 2px 0px;
      overflow: hidden;

      .customer-name {
        padding-bottom: 4px;
        border-bottom: 1px solid ${token('color.border')};
        padding-left: 1px;
        font-weight: bold;
        font-size: 13px;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .job-site-address {
        display: flex;
        flex-direction: column;
        font-size: 11px;
        padding: 4px 2px;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        a,
        span {
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
`
