import styled from 'styled-components'

import { MONOSPACE_FONT_FAMILY } from '@/utils/constants'

import { CELL_PADDING_HORIZONTAL } from '../editorConstants'

interface TextCellProps {
  value: string | null
  nullPlaceholder?: string
  monospace?: boolean
  noWrap?: boolean
  readOnly?: boolean
}

const TextCell = ({ value, nullPlaceholder = '-', monospace = false, noWrap = true, readOnly = false }: TextCellProps) => (
  <TextCellWrapper $monospace={monospace} $noWrap={noWrap} $readOnly={readOnly}>
    <span>{value ?? nullPlaceholder}</span>
  </TextCellWrapper>
)

export default TextCell

const TextCellWrapper = styled.div<{ $monospace: boolean; $noWrap: boolean; $readOnly: boolean }>`
  padding: 0 ${CELL_PADDING_HORIZONTAL}px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;

  span {
    font-family: ${props => (props.$monospace ? MONOSPACE_FONT_FAMILY : 'inherit')};
    ${props => (props.$noWrap ? { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' } : {})}
    opacity: ${props => (props.$readOnly ? 0.6 : 1)};
  }
`
