import { useMemo } from 'react'
import { Location, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { AtlassianNavigation, PrimaryButton } from '@atlaskit/atlassian-navigation'
import Button from '@atlaskit/button'
import { TopNavigation } from '@atlaskit/page-layout'
import { token } from '@atlaskit/tokens'

import LightbulbIcon from '@atlaskit/icon/glyph/lightbulb'
import SignOutIcon from '@atlaskit/icon/glyph/sign-out'

import { VERSION_NUMBER } from '@/utils/constants'
import { useSignOut } from '@/utils/useGraphQL'
import useIsDarkMode from '@/utils/useIsDarkMode'
import useRefreshToken from '@/utils/useRefreshToken'

const AppTopNavigation = () => {
  const location = useLocation()
  const pathname = location?.pathname ?? ''
  const navigate = useNavigate()
  const signOut = useSignOut()
  // const theme = useTheme()
  // const { setTheme, signOut } = useAppActions()
  const { toggleDarkMode } = useIsDarkMode()
  useRefreshToken()
  const links = useMemo(() => getLinks({ pathname, navigate }), [pathname, navigate])

  if (pathname.includes('work-order/')) return null
  return (
    <TopNavigation id='product-navigation' skipLinkTitle='Product Navigation' height={56} isFixed={true}>
      <ProfileMenuWrapper>
        <VersionNumberText>v{VERSION_NUMBER}</VersionNumberText>
        <Button appearance='subtle' iconBefore={<LightbulbIcon label='Lightbulb' />} onClick={toggleDarkMode}></Button>

        <Button appearance='subtle' iconBefore={<SignOutIcon label='Sign Out' />} onClick={signOut}></Button>
      </ProfileMenuWrapper>

      <AtlassianNavigation label='Top Level Navigation' primaryItems={links} renderProductHome={() => <Logo />} />
    </TopNavigation>
  )
}

export default AppTopNavigation

type getLinksProps = {
  pathname: Location['pathname']
  navigate: ReturnType<typeof useNavigate>
}

const linkItems = [
  { linkTo: '/jobs', label: 'Jobs', isHidden: false },
  { linkTo: '/materials', label: 'Materials', isHidden: false },
  { linkTo: '/customers', label: 'Customers', isHidden: false },
  { linkTo: '/calendar', label: 'Calendar', isHidden: false },
  { linkTo: '/dispatch', label: 'Dispatch', isHidden: false },
]

function getLinks({ pathname, navigate }: getLinksProps) {
  return linkItems.map(linkItem =>
    linkItem?.isHidden === true ? null : (
      <PrimaryButton
        key={linkItem.label}
        onClick={() => navigate(linkItem.linkTo)}
        isHighlighted={linkItem.label === 'Home' ? pathname === '/' : pathname.includes(linkItem.linkTo)}
        shouldFitContainer
      >
        {linkItem.label}
      </PrimaryButton>
    )
  )
}

const Logo = () => <div style={{ padding: '0 24px', fontWeight: '600', opacity: 0.3 }}>-Logo-</div>
const VersionNumberText = styled.div`
  font-size: 12px;
  color: ${token('color.text.disabled')};
  margin-right: 16px;
  font-family: ${token('font.family.code', 'monospace')};
  padding-top: 2px;
`
const ProfileMenuWrapper = styled.div`
  position: absolute;
  top: -2px;
  right: 16px;
  height: 56px;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
