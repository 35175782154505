export const VIEW_MODE_BY_DISPATCH = 'by-dispatch'
export const VIEW_MODE_BY_INSTALLER = 'by-installer'
export const VIEW_MODE_BY_VEHICLE = 'by-vehicle'

export const DRAGGABLE_VEHICLE_CARD_HEIGHT = 46
export const DRAGGABLE_VEHICLE_CARD_WIDTH = 180
export const DRAGGABLE_VEHICLE_CARD_GAP = 8

export const DRAGGABLE_INSTALLER_CARD_HEIGHT = 36
export const DRAGGABLE_INSTALLER_CARD_WIDTH = 160
export const DRAGGABLE_INSTALLER_CARD_GAP = 8
export const DRAGGABLE_INSTALLER_CARD_BORDER_RADIUS = 4

export const VEHICLE_SECTION_HORIZONTAL_PADDING = 12
export const VEHICLE_SECTION_WIDTH = DRAGGABLE_VEHICLE_CARD_WIDTH

export const INSTALLER_SIDEBAR_WIDTH = 24 + 32 + DRAGGABLE_INSTALLER_CARD_WIDTH
export const VEHICLES_SIDEBAR_WIDTH = 24 + 32 + DRAGGABLE_VEHICLE_CARD_WIDTH

export const DRAGGABLE_DISPATCH_CARD_WIDTH = 180
export const DRAGGABLE_DISPATCH_CARD_HEIGHT = 46
export const DRAGGABLE_DISPATCH_CARD_GAP = 8
export const DRAGGABLE_DISPATCH_SIDEBAR_WIDTH = 24 + 32 + DRAGGABLE_DISPATCH_CARD_WIDTH * 2 + DRAGGABLE_DISPATCH_CARD_GAP
export const DRAGGABLE_DISPATCH_SIDEBAR_WIDTH_SINGLE = 24 + 32 + DRAGGABLE_DISPATCH_CARD_WIDTH

// export const VEHICLE_DISPATCH_CARD_WIDTH = 180
export const VEHICLE_DISPATCH_CARD_HEIGHT = 56
export const VEHICLE_DISPATCH_CARD_GAP = 8
export const VEHICLE_DISPATCH_LEFT_COL_WIDTH = 200
