import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'

import Toggle from '@atlaskit/toggle'

import { graphql } from '@/gql'
import { GetWorkOrderQuery, SubmitWorkOrderValueMutationVariables } from '@/gql/graphql'
import useGraphQLClient from '@/utils/useAuthRequest'

import AppRightPanelAttribute from '@/components/AppRightPanelAttribute'
import ErrorBoundary from '@/components/ErrorBoundary'
import SpectrumTable, { ColumnDefsType } from '@/components/SpectrumTable'

import { CalendarWorkOrderType, DISPATCHES_BY_DATE_QUERY_KEY, GET_WORK_ORDER_QUERY_KEY } from './types'

import DispatchControls from './components/DispatchControls'

interface DispatchesTableType extends CalendarWorkOrderType {
  key: string
}

interface RightPanelSelectedWorkOrderProps {
  workOrderSelected: CalendarWorkOrderType | null
}

type QueriedWorkOrder = GetWorkOrderQuery['workOrder']

const RightPanelSelectedWorkOrder = ({ workOrderSelected }: RightPanelSelectedWorkOrderProps) => {
  const graphQLClient = useGraphQLClient()
  const [optimisticState, setOptimisticState] = useState<Partial<QueriedWorkOrder>>({})
  const workOrderId = workOrderSelected?.workOrderId ?? ''

  const { data, status, isPending, refetch } = useQuery({
    queryKey: [GET_WORK_ORDER_QUERY_KEY, workOrderId],
    queryFn: async () => graphQLClient.request(GET_WORK_ORDER, { workOrderId: `${workOrderId}` }),
    enabled: !!workOrderId,
  })
  const workOrder = data?.workOrder
  const dispatchesRaw: QueriedWorkOrder['dispatches'] = workOrder?.dispatches ?? []
  // @ts-expect-error pulling from different queries
  const dispatches: DispatchesTableType[] = dispatchesRaw.map(({ id: dispatchId, date }) => ({
    workOrder,
    workOrderId,
    dispatchId,
    dateString: date,
    key: dispatchId,
  }))

  const queryClient = useQueryClient()
  const { mutate: submitWorkOrderValue, isPending: isPendingMutation } = useMutation({
    mutationFn: async (variables: SubmitWorkOrderValueMutationVariables) => await graphQLClient.request(SUBMIT_WORK_ORDER_VALUE, variables),
    onError: error => {
      console.log('onError', error)
      setOptimisticState({})
    },
    onSuccess: data => {
      console.log('onSuccess', data)
      queryClient.invalidateQueries({ queryKey: [DISPATCHES_BY_DATE_QUERY_KEY] })
      refetch().then(() => setOptimisticState({}))
    },
  })

  const workOrderName = workOrder?.name ?? '-'
  const lastDispatchId: string | null = dispatches.length > 0 ? (dispatches[dispatches.length - 1]?.dispatchId ?? null) : null
  const columns = createColumns(lastDispatchId)

  const estimateTitle = workOrder?.estimate?.title ?? '-'
  const jobTitle = workOrder?.estimate?.job?.title ?? '-'
  const jobSite = workOrder?.estimate?.job?.projectSite?.name ?? '-'
  const to: string | undefined = workOrder?.estimate?.jobId ? `/jobs/${workOrder.estimate.jobId}/` : undefined
  const toPrint: string | undefined = workOrder?.id ? `/work-order/${workOrder.id}/${workOrderSelected?.dateString}` : undefined
  const customerName = workOrder?.estimate?.job?.customer?.name ?? '-'
  const toCustomer: string | undefined = workOrder?.estimate?.job?.customerId
    ? `/customers/${workOrder.estimate.job.customerId}`
    : undefined

  const confirmationIsChecked = optimisticState.isConfirmed ?? workOrder?.isConfirmed ?? false
  const isWorkCompleted = optimisticState.isWorkCompleted ?? workOrder?.isWorkCompleted ?? false
  return (
    <div style={{ opacity: isPending ? 0.3 : 1, transition: 'opacity 0.5s', position: 'relative' }}>
      <AppRightPanelAttribute label='Work Order Name'>{workOrderName}</AppRightPanelAttribute>

      <AppRightPanelAttribute label='Print Work Order' dim={!workOrder?.nextDispatchDate} to={toPrint} openNewTab>
        {toPrint ? 'Print View' : '-'}
      </AppRightPanelAttribute>

      <AppRightPanelAttribute label='Customer' to={toCustomer} noWrap openNewTab>
        {customerName}
      </AppRightPanelAttribute>

      <AppRightPanelAttribute label='Job' to={to} noWrap openNewTab>
        {jobTitle}
      </AppRightPanelAttribute>

      <AppRightPanelAttribute label='Job Site' to={to} noWrap openNewTab>
        {jobSite}
      </AppRightPanelAttribute>

      <AppRightPanelAttribute label='Estimate' to={to} noWrap openNewTab>
        {estimateTitle}
      </AppRightPanelAttribute>

      <AppRightPanelAttribute label='Next Dispatch' dim={!workOrder?.nextDispatchDate}>
        {workOrder?.nextDispatchDate ?? 'No Future Dispatches'}
      </AppRightPanelAttribute>

      <AppRightPanelAttribute label='Confirmed' toolTip={'Toggle Confirmed Status'}>
        <Toggle
          isChecked={confirmationIsChecked}
          isDisabled={
            isPending || isPendingMutation || workOrderSelected?.dateString === null || (isWorkCompleted && confirmationIsChecked)
          }
          onChange={() => handleToggle({ workOrderId: `${workOrderId}`, attribute: 'is_confirmed', value: !confirmationIsChecked })}
        />
      </AppRightPanelAttribute>

      <AppRightPanelAttribute label='Work Completed' toolTip={'Toggle Work Completed Status'}>
        <Toggle
          isChecked={isWorkCompleted}
          isDisabled={isPending || isPendingMutation || workOrderSelected?.dateString === null}
          onChange={() => handleToggle({ workOrderId: `${workOrderId}`, attribute: 'is_work_completed', value: !isWorkCompleted })}
        />
      </AppRightPanelAttribute>

      {/* <h3 style={{ marginBottom: 8 }}>Scheduled Dispatches</h3> */}
      <div style={{ position: 'relative', display: 'flex', flex: 1, alignSelf: 'stretch', overflow: 'hidden' }}>
        <ErrorBoundary>
          <SpectrumTable rows={dispatches} columns={columns} status={status} density='compact' key='dispatchId' />
        </ErrorBoundary>
      </div>
    </div>
  )
  function handleToggle(variables: SubmitWorkOrderValueMutationVariables) {
    setOptimisticState(prev => ({ ...prev, [variables.attribute]: variables.value }))
    submitWorkOrderValue(variables)
  }
}

export default RightPanelSelectedWorkOrder

const createColumns = (lastDispatchId: string | null): ColumnDefsType<CalendarWorkOrderType>[] => [
  { key: 'dateString', title: 'Dispatch Dates', width: 150, allowsResizing: false },
  {
    title: '',
    key: 'dispatchId',
    width: 120,
    align: 'end',
    allowsResizing: false,
    cell: row => <DispatchControls calendarWorkOrder={row} isLast={row.dispatchId === lastDispatchId} />,
  },
]

const SUBMIT_WORK_ORDER_VALUE = graphql(/* GraphQL */ `
  mutation SubmitWorkOrderValue($workOrderId: ID!, $attribute: String!, $value: JSON) {
    submitWorkOrderValue(workOrderId: $workOrderId, attribute: $attribute, value: $value) {
      success
      message
    }
  }
`)

const GET_WORK_ORDER = graphql(/* GraphQL */ `
  query GetWorkOrder($workOrderId: ID!) {
    workOrder(pk: $workOrderId) {
      id
      name
      status
      deferScheduleDate
      statusNotes
      materialCostTotal
      laborCostTotal
      totalCostTotal
      totalPrice
      marginPercent
      nextDispatchDate
      isConfirmed
      isWorkCompleted
      estimateTitle
      dispatches {
        id
        date
      }
      estimate {
        jobId
        id
        workItemCount
        modifiedAt
        title
        startDate
        status
        materialCostTotal
        laborCostTotal
        totalCostTotal
        marginPercent
        totalPriceCalculated
        totalPriceOverride
        totalPriceFinal
        modifiedById
        modifiedBy {
          fullName
          id
        }
        job {
          id
          title
          status
          createdAt
          modifiedAt
          projectSiteId
          projectSite {
            id
            name
            place {
              id
              displayName
              formattedAddress
              googleMapsUri
              city
              state
              zip
            }
          }
          customerId
          customer {
            id
            name
            businessPhone
            phoneOffice
            phoneMobile
            email
            workOrderNotes
          }
          createdById
          createdBy {
            fullName
            id
          }
          modifiedById
          modifiedBy {
            fullName
            id
          }
        }
      }
    }
  }
`)
