import React from 'react'
import styled from 'styled-components'

import { token } from '@atlaskit/tokens'

import HoveredDropZone from '@/components/HoveredDropZone'

interface SidebarScrollableListProps {
  ref: React.RefObject<HTMLDivElement | null>
  isHovered: boolean
  children: React.ReactNode
  headerText: string
  side: 'LEFT' | 'RIGHT'
  noPadding?: boolean
  actions?: React.ReactNode
}
const SidebarScrollableList = ({ ref, isHovered, children, headerText, side, noPadding, actions }: SidebarScrollableListProps) => {
  return (
    <StyledVehiclesSidebar $side={side} ref={ref} $noPadding={noPadding}>
      <HoveredDropZone isHovered={isHovered} hoveredText='Drop to Assign' hoveredTextLineTwo='Vehicle' />

      <div className='sidebar-list-header'>
        <h4>{headerText}</h4>
        {actions}
      </div>

      <div className='sidebar-scrollable-list'>{children}</div>
    </StyledVehiclesSidebar>
  )
}

export default SidebarScrollableList

interface StyledVehiclesSidebarProps {
  $side: 'LEFT' | 'RIGHT'
  $noPadding?: boolean
}
const StyledVehiclesSidebar = styled.div<StyledVehiclesSidebarProps>`
  position: absolute;
  inset: 0;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;

  /* border: 5px solid red; */

  .sidebar-list-header {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: ${props => (props.$side === 'RIGHT' ? '24px' : '32px')};
    padding-right: ${props => (props.$side === 'RIGHT' ? '32px' : '24px')};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${token('color.border')};
    h4 {
      font-weight: 500;
      color: ${token('color.text.subtle')};
    }
  }

  .sidebar-scrollable-list {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 1 auto;
    padding-top: ${props => (props.$noPadding ? 0 : 12)}px;
    padding-bottom: ${props => (props.$noPadding ? 0 : 24)}px;
    padding-left: ${props => (props.$noPadding ? 0 : props.$side === 'RIGHT' ? '24px' : '32px')};
    padding-right: ${props => (props.$noPadding ? 0 : props.$side === 'RIGHT' ? '32px' : '24px')};
  }

  .sidebar-none-found {
    padding: 12px;
    font-size: 14px;
    font-weight: 500;
    color: ${token('color.text.subtlest')};
  }
`
