import { useAtom, useAtomValue } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { useRef } from 'react'
import styled from 'styled-components'

import { IconButton } from '@atlaskit/button/new'
import EditorCollapseIcon from '@atlaskit/icon/glyph/editor/collapse'
import EditorExpandIcon from '@atlaskit/icon/glyph/editor/expand'
import { token } from '@atlaskit/tokens'

import { MONOSPACE_FONT_FAMILY } from '@/utils/constants'
import { CardColorOptions, TRIP_COLORS } from '@/utils/utilities'

import { dispatchIdsByTripAtom, SidebarZone } from './dispatchTypes'

import { useAvailableDispatchesCount } from './hooks/useDispatchDateQuery'
import useSidebarDropZone from './hooks/useDropZoneSidebars'

import DraggableDispatchCard from './components/DraggableDispatchCard'
import SidebarScrollableList from './components/SidebarScrollableList'

const SIDE = 'LEFT'

const zone: SidebarZone = 'DISPATCH_SIDEBAR'

export const isDispatchSidebarExpandedAtom = atomWithStorage<boolean>('isDispatchSidebarExpanded', true, undefined, { getOnInit: true })

const SidebarDispatchesActions = () => {
  const [isExpanded, setIsExpanded] = useAtom(isDispatchSidebarExpandedAtom)
  return (
    <IconButton
      icon={iconProps =>
        isExpanded ? (
          <EditorCollapseIcon {...iconProps} label='Collapse' primaryColor={token('color.icon.subtlest')} />
        ) : (
          <EditorExpandIcon {...iconProps} label='Expand' primaryColor={token('color.icon.subtlest')} />
        )
      }
      label='Edit'
      onClick={() => setIsExpanded(prev => !prev)}
      appearance='subtle'
    />
  )
}

interface SidebarDispatchesProps {
  dateString: string
}
const SidebarDispatches = ({ dateString }: SidebarDispatchesProps) => {
  const sidebarRef = useRef<HTMLDivElement | null>(null)
  const dispatchCount = useAvailableDispatchesCount()
  const headerText = `${dispatchCount} Dispatch${dispatchCount === 1 ? '' : 'es'}`
  const dispatchIdsByTrip = useAtomValue(dispatchIdsByTripAtom)
  const dispatchIdsByTripDateString = (dispatchIdsByTrip?.dateString ?? null) as string | null
  const wrongDate = dateString !== dispatchIdsByTripDateString
  const trips = Object.keys(dispatchIdsByTrip)
  if (trips.length > 0 && wrongDate) console.warn('wrong date', { dateString, dispatchIdsByTripDateString, dispatchIdsByTrip })

  const isHovered = useSidebarDropZone({ zone, sidebarRef })

  return (
    <SidebarScrollableList
      ref={sidebarRef}
      isHovered={isHovered}
      headerText={headerText}
      side={SIDE}
      noPadding
      actions={<SidebarDispatchesActions />}
    >
      {trips.length === 0 ? (
        <div>No Dispatches Scheduled</div>
      ) : (
        trips.map(trip => {
          if (trip === 'dateString') return
          const dispatchIds = dispatchIdsByTrip[trip] as string[]

          const accentColor: CardColorOptions = TRIP_COLORS?.[trip] ?? 'orange'
          const textColor = token(!accentColor ? `color.text.subtlest` : `color.text.accent.${accentColor}`)
          const backgroundColor = token(!accentColor ? `elevation.surface.raised` : `color.background.accent.${accentColor}.subtlest`)
          const borderColor = token(!accentColor ? `color.border` : `color.border.accent.${accentColor}`)

          return (
            <TripWrapper key={trip}>
              <TripHeader $textColor={textColor} $backgroundColor={backgroundColor} $borderColor={borderColor}>
                <span>
                  {trip} <span className='dispatch-count'>({wrongDate ? 0 : dispatchIds.length})</span>
                </span>
              </TripHeader>

              <TripGroup>
                {wrongDate || !dispatchIds
                  ? null
                  : dispatchIds.map(dispatchId => <DraggableDispatchCard key={dispatchId} dispatchId={dispatchId} />)}
              </TripGroup>
            </TripWrapper>
          )
        })
      )}
    </SidebarScrollableList>
  )
}

export default SidebarDispatches

interface TripHeaderProps {
  $textColor: string
  $backgroundColor: string
  $borderColor: string
}
const TripWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-self: stretch;
`
const TripHeader = styled.div<TripHeaderProps>`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  align-self: stretch;
  border-bottom: 1px solid ${({ $borderColor }) => $borderColor};
  padding: 2px 24px 2px 32px;
  span {
    font-size: 12px;
    font-weight: ${token('font.weight.semibold')};
    text-transform: uppercase;
    color: ${({ $textColor }) => $textColor};

    .dispatch-count {
      font-family: ${MONOSPACE_FONT_FAMILY};
      font-size: 11px;
      opacity: 0.6;
    }
  }
`

const TripGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${token('space.050')};
  padding: 8px 24px 16px 32px;
`
