import { GetDispatchesByDateQuery } from '@/gql/graphql'

export const CALENDAR_WORK_ORDERS_QUERY_KEY = 'workOrdersToSchedule'
export const GET_WORK_ORDER_QUERY_KEY = 'workOrder'
export const DISPATCHES_BY_DATE_QUERY_KEY = 'dispatchesByDate'

export interface SchedulerCardDraggableProps {
  workOrderId: string
  tripName?: string | null
  dispatchId?: string | null
}

export type QueryDispatchType = GetDispatchesByDateQuery['dispatchesByDate'][0]['dispatches'][0]

export type CalendarWorkOrderType = {
  workOrderId: string
  workOrder: QueryDispatchType['workOrder']
  dispatchId?: string | null
  dateString?: string | null
  jobId?: string | null
  estimateId?: string | null
  estimateTitle?: string | null
}
