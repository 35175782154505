import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

import { graphql } from '@/gql'
import { GetStaffListQuery } from '@/gql/graphql'
import useGraphQLClient from '@/utils/useAuthRequest'

export type Staff = GetStaffListQuery['users'][0]

const useStaffListQuery = () => {
  const graphQLClient = useGraphQLClient()

  const { data, error, status } = useQuery({
    queryKey: [GET_STAFF_LIST_QUERY_KEY],
    queryFn: () => graphQLClient.request(GET_STAFF_LIST),
  })

  const { staffMap, staffList } = useMemo(() => {
    const list = (data?.users ?? []) as Staff[]
    const idMap = list.reduce(
      (acc, staff) => {
        acc[staff.id] = staff
        return acc
      },
      {} as Record<string, Staff>
    )
    return { staffMap: idMap, staffList: list }
  }, [data])

  return { staffList, error, status, staffMap }
}

export default useStaffListQuery

const GET_STAFF_LIST_QUERY_KEY = 'staffList'
const GET_STAFF_LIST = graphql(/* GraphQL */ `
  query GetStaffList {
    users {
      id
      username
      fullName
      firstName
      lastName
      isSuperuser

      isSalesman
      isInstaller
      isHourly
    }
  }
`)
