import { useAtomValue } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'

import { sortedVehicleDispatchIdsByVehicleIdAtom, vehicleDispatchIdsByVehicleIdAtom } from '../dispatchTypes'

const useSelectedVehicleDispatchIds = (vehicleId: string) => {
  const sortedVehicleDispatchIdsAtom = useMemo(
    () => selectAtom(sortedVehicleDispatchIdsByVehicleIdAtom, state => (state?.[vehicleId] ?? []) as string[]),
    [vehicleId]
  )
  const vehicleDispatchIdsAtom = useMemo(
    () => selectAtom(vehicleDispatchIdsByVehicleIdAtom, state => (state?.[vehicleId] ?? []) as string[]),
    [vehicleId]
  )
  const ids = useAtomValue(vehicleDispatchIdsAtom)
  const sortedIds = useAtomValue(sortedVehicleDispatchIdsAtom)
  const result = useMemo(() => {
    const sortedIdsLength = sortedIds?.length ?? 0
    const idsLength = ids?.length ?? 0
    if (sortedIdsLength < idsLength) return ids
    return sortedIds ?? ids ?? []
  }, [sortedIds, ids])
  return result
}

export default useSelectedVehicleDispatchIds
