import { Temporal } from '@js-temporal/polyfill'
import { motion, MotionValue, useScroll, useSpring, useTransform } from 'framer-motion'
import { useEffect, useRef } from 'react'
import styled from 'styled-components'

import { token } from '@atlaskit/tokens'

import { DAYS_OF_WEEK_ABBR_LIST } from '@/utils/constants'

import useIsVisible from '../hooks/useIsVisible'

import CalendarDayCell from './CalendarDayCell'

interface CalendarWeeksProps {
  firstWeekDate: Temporal.PlainDate
  lastWeekDate: Temporal.PlainDate
  today: Temporal.PlainDate
}

const CalendarWeeks = ({ firstWeekDate, lastWeekDate, today }: CalendarWeeksProps) => {
  const scrollingContainerRef = useRef<HTMLDivElement>(null)
  const componentRef = useRef<HTMLDivElement>(null)
  const isVisible = useIsVisible(componentRef as React.RefObject<HTMLElement>)
  const didScrollOnce = useRef(false)
  const { scrollY } = useScroll({ container: scrollingContainerRef as React.RefObject<HTMLElement> })
  const smoothScrollY = useSpring(scrollY, { stiffness: 400, damping: 90 })

  const isScrolled = useTransform(smoothScrollY, [0, 1], [false, true])

  const differenceInWeeks = lastWeekDate.since(firstWeekDate, { largestUnit: 'weeks' }).weeks
  const mondays = Array.from({ length: differenceInWeeks + 1 }, (_, i) => (i === 0 ? firstWeekDate : firstWeekDate.add({ weeks: i })))

  useEffect(() => {
    if (isVisible && !didScrollOnce.current) {
      const container = scrollingContainerRef.current
      if (container) {
        // Find the index of the current week
        const currentWeekIndex = mondays.findIndex(monday => today.since(monday, { largestUnit: 'weeks' }).weeks === 0)

        // Scroll to the current week
        if (currentWeekIndex !== -1) {
          const weekElements = container.querySelectorAll('.eh-week-row')
          if (weekElements[currentWeekIndex]) {
            weekElements[currentWeekIndex].scrollIntoView({ behavior: 'smooth', block: 'start' })
            didScrollOnce.current = true
          }
        }
      }
    }
  }, [mondays, today, isVisible])

  return (
    <CalendarContainer $isScrolled={isScrolled} ref={componentRef}>
      <div className='eh-header-row'>
        {DAYS_OF_WEEK_ABBR_LIST.map(day => (
          <div key={day} className='eh-header-cell'>
            {day}
          </div>
        ))}
      </div>

      <ScrollingRowsContainer ref={scrollingContainerRef as React.RefObject<HTMLElement>}>
        <div className='eh-scrolling-rows'>
          {mondays.map(monday => {
            const sunday = monday.subtract({ days: 1 })
            return (
              <div className='eh-week-row' key={sunday.toString()}>
                {DAYS_OF_WEEK_ABBR_LIST.map((day, ind) => {
                  const date = sunday.add({ days: ind })
                  return <CalendarDayCell date={date} today={today} key={day} />
                })}
              </div>
            )
          })}
        </div>
      </ScrollingRowsContainer>
    </CalendarContainer>
  )
}

export default CalendarWeeks

const CalendarContainer = styled(motion.div)<{ $isScrolled: boolean | MotionValue<boolean> }>`
  position: absolute;
  inset: 0;
  left: ${token('space.400')};
  right: ${token('space.400')};
  overflow: hidden;
  z-index: 0;

  .eh-header-row {
    position: relative;
    z-index: 3;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: stretch;

    height: 1.6rem;

    border: 1px solid ${token('color.border')};
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;

    background: ${token('elevation.surface')};

    transition: box-shadow 0.2s;
    box-shadow: ${({ $isScrolled }) => ($isScrolled ? token('elevation.shadow.overflow') : 'none')};

    .eh-header-cell + .eh-header-cell {
      border-left: 1px solid ${token('color.border')};
    }
    .eh-header-cell {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      font-size: 12px;
      letter-spacing: 0.3px;
      font-weight: 600;
      text-transform: uppercase;
      color: ${token('color.text.subtlest')};
    }
  }
`

const ScrollingRowsContainer = styled(motion.div)`
  z-index: 1;

  position: absolute;
  inset: 0;
  top: 1.7rem;

  display: flex;
  flex-direction: column;
  flex: 1;

  min-height: 0;
  max-height: 100%;

  overflow-y: auto;

  border-left: 1px solid ${token('color.border')};
  border-right: 1px solid ${token('color.border')};

  .eh-scrolling-rows {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;

    .eh-week-row {
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid ${token('color.border')};
      /* min-height: 160px; */
      position: relative;
      width: 100%; // Ensure the WeekRow takes up the full width of its container
      transition: height 0.08s;
      height: auto; // Allow the height of WeekRow to grow based on its children
      align-items: stretch; // Align children at the top to allow them to grow downwards

      .eh-day-cell + .eh-day-cell {
        border-left: 1px solid ${token('color.border')};
      }
    }
  }
`
