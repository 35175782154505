import { useAtomValue } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'

import { Dispatch, dispatchesByIdAtom } from '../dispatchTypes'

const useSelectedDispatch = (dispatchId: string | null): Dispatch | null => {
  const dispatchAtom = useMemo(
    () => selectAtom(dispatchesByIdAtom, dispatchesById => (!dispatchId ? null : dispatchesById[dispatchId])),
    [dispatchId]
  )
  return useAtomValue(dispatchAtom)
}
export default useSelectedDispatch
