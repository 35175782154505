import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

import { graphql } from '@/gql'
import { GetVehiclesQuery } from '@/gql/graphql'
import useGraphQLClient from '@/utils/useAuthRequest'

export type Vehicle = GetVehiclesQuery['vehicles'][0]

const useVehiclesQuery = () => {
  const graphQLClient = useGraphQLClient()

  const { data, error, status } = useQuery({
    queryKey: [GET_VEHICLES_QUERY_KEY],
    queryFn: () => graphQLClient.request(GET_VEHICLES),
  })

  const { vehicleMap, vehicles } = useMemo(() => {
    const list = (data?.vehicles ?? []) as Vehicle[]
    const idMap = list.reduce(
      (acc, vehicle) => {
        acc[vehicle.id] = vehicle
        return acc
      },
      {} as Record<string, Vehicle>
    )
    return { vehicleMap: idMap, vehicles: list }
  }, [data])

  return { vehicles, vehicleMap, error, status }
}

export default useVehiclesQuery

export const GET_VEHICLES_QUERY_KEY = 'vehicles'
export const GET_VEHICLES = graphql(/* GraphQL */ `
  query GetVehicles {
    vehicles {
      id
      name
      licensePlate
      make
      model
      year
      color
    }
  }
`)
