import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useCallback, useEffect, useMemo } from 'react'

import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'

import type { Vehicle } from '@/utils/queryHooks/useVehiclesQuery'

import useSelectedVehicleDispatchIds from './useSelectedVehicleDispatchIds'

import { dispatchIdsByVehicleIdAtom, DragSourceData, hoveredDropZoneAtom, itemPickedUpAtom } from '../dispatchTypes'

interface UseDropZoneVehicleContainerProps {
  vehicle: Vehicle
  vehicleHeaderRef: React.RefObject<HTMLDivElement | null>
  vehicleBodyRef: React.RefObject<HTMLDivElement | null>
}

const useDropZoneVehicleContainer = ({ vehicle, vehicleHeaderRef, vehicleBodyRef }: UseDropZoneVehicleContainerProps) => {
  const setItemHovered = useSetAtom(hoveredDropZoneAtom)
  const vehicleId = vehicle.id
  const vehicleDispatchIds = useSelectedVehicleDispatchIds(vehicleId)

  const onDragLeave = useCallback(() => setItemHovered(prev => (prev.vehicleId === vehicleId ? {} : prev)), [vehicleId, setItemHovered])

  useEffect(() => {
    const dropTargetElement = vehicleHeaderRef.current
    if (!dropTargetElement) throw new Error('ref not set correctly')

    return dropTargetForElements({
      element: dropTargetElement,
      getData: () => ({ zone: 'VEHICLE_HEADER', vehicleId }),
      onDragEnter: () => setItemHovered({ vehicleId, zone: 'VEHICLE_HEADER' }),
      onDragLeave,
      canDrop: ({ source }) => {
        const { installerId } = source.data ?? {}
        if (!installerId) return false
        return true
      },
    })
  }, [vehicleId, onDragLeave, setItemHovered, vehicleDispatchIds, vehicleHeaderRef])

  useEffect(() => {
    const dropTargetElement = vehicleBodyRef.current
    if (!dropTargetElement) throw new Error('ref not set correctly')

    return dropTargetForElements({
      element: dropTargetElement,
      getData: () => ({ zone: 'VEHICLE_BODY', vehicleId }),
      onDragEnter: () => vehicleId !== null && setItemHovered(prev => ({ ...prev, vehicleId, zone: 'VEHICLE_BODY' })),
      onDragLeave,
      canDrop: ({ source }) => {
        const { dispatchId, vehicleDispatchId } = (source.data ?? {}) as DragSourceData
        if (!dispatchId) return false
        if (vehicleDispatchIds.includes(vehicleDispatchId ?? '')) return false
        return true
      },
    })
  }, [vehicleId, onDragLeave, setItemHovered, vehicleDispatchIds, vehicleBodyRef])
}

export default useDropZoneVehicleContainer

export const useIsVehicleHeaderHoveredByInstaller = (vehicleId: string) => {
  const isHoveredAtom = useMemo(
    () => selectAtom(hoveredDropZoneAtom, itemHovered => itemHovered?.vehicleId === vehicleId && itemHovered?.zone === 'VEHICLE_HEADER'),
    [vehicleId]
  )
  const isInstallerPickedUpAtom = useMemo(() => selectAtom(itemPickedUpAtom, itemPickedUp => !!itemPickedUp?.installerId), [])
  const isInstallerPickedUp = useAtomValue(isInstallerPickedUpAtom)
  return useAtomValue(isHoveredAtom) && isInstallerPickedUp
}

export const useIsVehicleBodyHoveredByDispatch = (vehicleId: string) => {
  const isHoveredAtom = useMemo(
    () =>
      selectAtom(hoveredDropZoneAtom, itemHovered => {
        const isHovered = itemHovered?.vehicleId === vehicleId && itemHovered?.zone === 'VEHICLE_BODY'
        return isHovered
      }),
    [vehicleId]
  )
  const pickedUpDispatchIdAtom = useMemo(() => selectAtom(itemPickedUpAtom, itemPickedUp => itemPickedUp?.dispatchId ?? null), [])
  const pickedUpDispatchId = useAtomValue(pickedUpDispatchIdAtom)
  const pickedUpItemIsDispatch = pickedUpDispatchId !== null
  const pickedUpDispatchExistsInThisVehicleAtom = useMemo(
    () =>
      selectAtom(dispatchIdsByVehicleIdAtom, dispatchIdsByVehicleId => {
        const dispatchIds = dispatchIdsByVehicleId[vehicleId] ?? []
        return dispatchIds.includes(pickedUpDispatchId ?? '')
      }),
    [vehicleId, pickedUpDispatchId]
  )
  const isHovered = useAtomValue(isHoveredAtom) && pickedUpItemIsDispatch
  const alreadyExists = useAtomValue(pickedUpDispatchExistsInThisVehicleAtom)
  return { isHovered, alreadyExists }
}
