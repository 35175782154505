import { AnimatePresence, motion } from 'framer-motion'
import styled from 'styled-components'

import { DispatchAssignmentAction as Actions } from '@/gql/graphql'
import { token } from '@atlaskit/tokens'

import { useAtomValue } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo, useRef } from 'react'
import {
  DRAGGABLE_INSTALLER_CARD_BORDER_RADIUS,
  DRAGGABLE_INSTALLER_CARD_HEIGHT,
  DRAGGABLE_INSTALLER_CARD_WIDTH,
  VEHICLE_DISPATCH_LEFT_COL_WIDTH,
} from '../dispatchConstants'
import { centerSectionWidthAtom, DispatchDropZone, itemMouseOverAtom, pendingMutationsAtom } from '../dispatchTypes'
import useDropZoneVehicleInstallers from '../hooks/useDropZoneVehicleInstallers'
import useIsDropZoneHovered from '../hooks/useIsDropZoneHovered'
import InstallerCard from './InstallerCard'

interface VehicleDispatchInstallerSectionProps {
  vehicleDispatchId: string
  vehicleId: string
  installerAssignmentsIds: string[]
}

const installerPadding = 8
const maxOffset = DRAGGABLE_INSTALLER_CARD_WIDTH + installerPadding

const useHighlightedInstallerId = (installerAssignmentsIds: string[]): string | null => {
  const installerIdAtom = useMemo(
    () =>
      selectAtom(itemMouseOverAtom, mouseOver => {
        if (mouseOver.itemIdentifiedVariant === 'INSTALLER' && mouseOver?.installerId) {
          const installerId = mouseOver.installerId
          if (installerAssignmentsIds.includes(installerId)) return installerId
        }
        return null
      }),
    [installerAssignmentsIds]
  )
  return useAtomValue(installerIdAtom)
}

const zone: DispatchDropZone = 'VEHICLE_DISPATCH_INSTALLERS'

const useCombinedInstallerIds = (installerAssignmentsIds: string[], vehicleDispatchId: string, vehicleId: string) => {
  const { itemPickedUp } = useIsDropZoneHovered({ zone, vehicleDispatchId, vehicleId })
  const selectedPendingAtom = useMemo(
    () =>
      selectAtom(pendingMutationsAtom, pendingMutations => {
        let adding = null
        let removing = null
        if (pendingMutations.installerId) {
          if (pendingMutations.action === Actions.CreateInstallerAssignmentAll)
            adding = vehicleId === pendingMutations.targetVehicleId ? pendingMutations.installerId : null
          if (
            pendingMutations.action &&
            [(Actions.CreateInstallerAssignment, Actions.CopyInstallerAssignment, Actions.MoveInstallerAssignment)].includes(
              pendingMutations.action
            )
          )
            adding = vehicleDispatchId === pendingMutations.targetVehicleDispatchId ? pendingMutations.installerId : null
          if (pendingMutations.action === Actions.RemoveInstallerAssignment)
            removing = vehicleDispatchId === pendingMutations.originVehicleDispatchId ? pendingMutations.installerId : null
        }
        return { addingInstallerId: adding, removingInstallerId: removing }
      }),
    [vehicleDispatchId, vehicleId]
  )

  const { addingInstallerId, removingInstallerId } = useAtomValue(selectedPendingAtom)

  return useMemo(() => {
    const pickedUpInstallerId = itemPickedUp?.installerId
    const idToAdd = addingInstallerId ?? pickedUpInstallerId ?? null
    const alreadyAssigned = idToAdd === null ? false : installerAssignmentsIds.includes(idToAdd)
    const allIds = [...installerAssignmentsIds]
    if (idToAdd !== null && !alreadyAssigned) allIds.push(idToAdd)
    if (removingInstallerId) allIds.splice(allIds.indexOf(removingInstallerId), 1)
    return { combinedInstallerIds: allIds, pendingInstallerId: idToAdd, alreadyAssigned }
  }, [installerAssignmentsIds, itemPickedUp, addingInstallerId, removingInstallerId])
}

const VehicleDispatchInstallerSection = ({
  installerAssignmentsIds,
  vehicleId,
  vehicleDispatchId,
}: VehicleDispatchInstallerSectionProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const centerSectionWidth = useAtomValue(centerSectionWidthAtom)

  const highlightedInstallerId = useHighlightedInstallerId(installerAssignmentsIds)

  useDropZoneVehicleInstallers({ vehicleId, vehicleDispatchId, containerRef })

  const { combinedInstallerIds, pendingInstallerId, alreadyAssigned } = useCombinedInstallerIds(
    installerAssignmentsIds,
    vehicleDispatchId,
    vehicleId
  )

  const count = combinedInstallerIds.length
  const cardOffset = useMemo(() => calcOffset(centerSectionWidth, count), [centerSectionWidth, count])
  const shouldShowShadow = cardOffset < maxOffset - installerPadding

  return (
    <StyledInstallerSection $shouldShowShadow={shouldShowShadow} ref={containerRef}>
      {count === 0 && (
        <div className='job-card-installers-header'>
          <span>No Crew</span>
        </div>
      )}

      <div className='installer-cards-container'>
        <AnimatePresence initial={false}>
          {combinedInstallerIds.map((installerId, ind) => {
            const left = cardOffset * ind
            const zIndex = highlightedInstallerId === installerId ? 3 : 0
            const isPending = installerId === pendingInstallerId && !alreadyAssigned
            return (
              <motion.div
                key={installerId}
                layout
                initial={{ opacity: 0, left }}
                animate={{ opacity: 1, left }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.12 }}
                style={{ zIndex }}
              >
                <InstallerCard installerId={installerId} vehicleDispatchId={vehicleDispatchId} isPending={isPending} />
              </motion.div>
            )
          })}
        </AnimatePresence>
      </div>
    </StyledInstallerSection>
  )
}

export default VehicleDispatchInstallerSection

function calcOffset(centerSectionWidth: number | null, count: number) {
  if (!centerSectionWidth) return 200
  const vehicleWidth = centerSectionWidth - (24 + 24 + 12 + 12)
  const rightColWidth = vehicleWidth - (VEHICLE_DISPATCH_LEFT_COL_WIDTH + installerPadding)
  const remainingWidth = rightColWidth - (DRAGGABLE_INSTALLER_CARD_WIDTH + 2 * installerPadding)
  const offset = remainingWidth / (count - 1)
  return offset > maxOffset ? maxOffset : offset < 30 ? 30 : offset
}
const StyledInstallerSection = styled.div<{ $shouldShowShadow: boolean }>`
  position: relative;
  min-width: 200px;
  flex: 1 1 auto;

  border-left: 1px solid ${token('color.border')};
  padding: 0px;

  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;

  .installer-cards-container {
    position: absolute;
    left: 7px;
    right: 7px;
    height: ${DRAGGABLE_INSTALLER_CARD_HEIGHT + 0}px;

    border-radius: ${DRAGGABLE_INSTALLER_CARD_BORDER_RADIUS}px;
    overflow: hidden;

    > div {
      position: absolute;

      > div {
        transition: box-shadow 0.2s ease-in-out;
        box-shadow: ${({ theme, $shouldShowShadow }) =>
          $shouldShowShadow
            ? theme.colorMode === 'dark'
              ? '0px 0px 0px rgba(57, 66, 74, 1), -3px 0px 6px rgba(3, 4, 4, 1),0px 0px 1px rgba(3, 4, 4, 0.5)'
              : '-3px 0px 6px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(9, 30, 66, 0.31)'
            : 'none'};
      }
    }
  }

  .job-card-installers-header {
    position: absolute;
    top: 2px;
    left: 12px;
    bottom: 0;

    font-size: 15px;
    line-height: 15px;
    margin-bottom: 4px;
    color: ${token('color.text.subtle')};
    opacity: 0.6;
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`
