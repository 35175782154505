import { useSetAtom } from 'jotai'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

import { draggable } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { token } from '@atlaskit/tokens'

import CopyIcon from '@atlaskit/icon/glyph/copy'
import PersonCircleIcon from '@atlaskit/icon/glyph/person-circle'

import useStaffById from '@/utils/queryHooks/useStaffById'
import { Staff } from '@/utils/queryHooks/useStaffListQuery'

import useIsDragging from '../hooks/useIsDragging'
import useIsShiftKeyPressed from '../hooks/useIsShiftKeyPressed'
import useSelectedInstallerAssignments from '../hooks/useSelectedInstallerAssignments'

import { DRAGGABLE_INSTALLER_CARD_HEIGHT, DRAGGABLE_INSTALLER_CARD_WIDTH } from '../dispatchConstants'
import { DragSourceData, itemMouseOverAtom, itemPickedUpAtom } from '../dispatchTypes'
import useIsMouseOverGlobal from '../hooks/useIsMouseOverGlobal'

const PLACEHOLDER_STAFF: Staff = {
  id: 'placeholder',
  fullName: 'Staff',
  firstName: '',
  lastName: '',
  username: '',
  isSuperuser: false,
  isSalesman: false,
  isInstaller: false,
  isHourly: false,
}

interface InstallerCardProps {
  installerId: string
  vehicleDispatchId?: string | null
  vehicleId?: string | null
  isPending?: boolean
}

const InstallerCard = ({ installerId, vehicleDispatchId, vehicleId, isPending = false }: InstallerCardProps) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const setItemPickedUp = useSetAtom(itemPickedUpAtom)
  const assignedVehicleDispatchIds = useSelectedInstallerAssignments(installerId)
  const setItemMouseOver = useSetAtom(itemMouseOverAtom)
  const isMouseOverGlobal = useIsMouseOverGlobal(installerId)

  const [isMouseOver, setIsMouseOver] = useState(false)

  const draggableItem: DragSourceData = useMemo(
    () => ({
      installerId,
      vehicleDispatchId,
      vehicleId,
      assignedVehicleDispatchIds,
      itemIdentifiedVariant: 'INSTALLER',
    }),
    [installerId, vehicleDispatchId, vehicleId, assignedVehicleDispatchIds]
  )

  const handleMouseEnter = useCallback(() => {
    setIsMouseOver(true)
    setItemMouseOver(draggableItem)
  }, [draggableItem, setItemMouseOver])
  const handleMouseLeave = useCallback(() => {
    setIsMouseOver(false)
    setItemMouseOver(prev => (prev.installerId === installerId ? {} : prev))
  }, [installerId, setItemMouseOver])

  const onDrop = useCallback(() => setItemPickedUp(prev => (prev.installerId !== installerId ? prev : {})), [installerId, setItemPickedUp])
  const onDragStart = useCallback(() => setItemPickedUp(draggableItem), [draggableItem, setItemPickedUp])
  const getInitialData = useCallback(() => draggableItem, [draggableItem])

  useEffect(() => {
    if (ref.current) return draggable({ element: ref.current, getInitialData, onDragStart, onDrop })
  }, [getInitialData, onDragStart, onDrop])

  const isShiftKeyPressed = useIsShiftKeyPressed()
  const { isDragging, isDraggingExactMatch } = useIsDragging(draggableItem)
  const isCopyMode = useMemo(
    () => Boolean(vehicleDispatchId) && isShiftKeyPressed && isMouseOver,
    [vehicleDispatchId, isShiftKeyPressed, isMouseOver]
  )

  const staff = useStaffById(installerId) ?? (installerId === 'placeholder' ? PLACEHOLDER_STAFF : null)
  const name = staff?.fullName ?? 'NOT FOUND'

  return (
    <StyledInstallerCard
      ref={ref}
      $isDragging={isDraggingExactMatch}
      $isCopyMode={isCopyMode}
      $isMouseOverGlobal={isMouseOverGlobal || isDragging}
      $isPending={isPending}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className='installer-card-icon'>
        {isCopyMode ? (
          <CopyIcon label='Copy Installer' primaryColor={token('color.icon')} />
        ) : (
          <PersonCircleIcon label='Installer' primaryColor={token('color.icon.subtle')} />
        )}
      </div>

      <div className='installer-card-title'>
        <span>{name}</span>
      </div>
    </StyledInstallerCard>
  )
}

interface StyledInstallerCardProps {
  $isDragging: boolean
  $isCopyMode: boolean
  $isMouseOverGlobal: boolean
  $isPending: boolean
}

const StyledInstallerCard = styled.div<StyledInstallerCardProps>`
  display: flex;
  flex-direction: row;
  align-items: center;

  border: 1px solid ${({ $isMouseOverGlobal }) => ($isMouseOverGlobal ? token('color.border.accent.blue') : token('color.border'))};
  border-radius: 4px;

  background-color: ${({ $isMouseOverGlobal }) =>
    $isMouseOverGlobal ? token('color.background.selected') : token('elevation.surface.raised')};
  width: ${DRAGGABLE_INSTALLER_CARD_WIDTH}px;
  height: ${DRAGGABLE_INSTALLER_CARD_HEIGHT}px;
  box-sizing: border-box;
  cursor: grab;
  ${({ $isMouseOverGlobal }) => $isMouseOverGlobal && 'z-index: 3;'}
  transition: box-shadow 0.1s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 0 0 0px ${token('color.border')};
  &:hover {
    box-shadow: ${token('elevation.shadow.raised')};
  }

  opacity: ${({ $isDragging }) => ($isDragging ? 0.5 : 1)};
  transition: opacity 0.1s ease-in-out;
  user-select: none;
  > div {
    opacity: ${({ $isPending }) => ($isPending ? 0.5 : 1)};
  }
  .installer-card-icon {
    padding: 4px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
  }
  .installer-card-title {
    padding-right: 12px;
    font-weight: 500;
    user-select: none;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`

export default InstallerCard
