import { atom, useAtom } from 'jotai'
import { useCallback, useEffect } from 'react'

const isShiftKeyPressedAtom = atom(false)

const useIsShiftKeyPressed = () => {
  const [isShiftKeyPressed, setIsShiftKeyPressed] = useAtom(isShiftKeyPressedAtom)

  // Handler for keydown event
  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Shift') {
        e.preventDefault() // Prevent default behavior
        setIsShiftKeyPressed(true)
      }
    },
    [setIsShiftKeyPressed]
  )

  // Handler for keyup event
  const handleKeyUp = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Shift') {
        setIsShiftKeyPressed(false)
      }
    },
    [setIsShiftKeyPressed]
  )

  useEffect(() => {
    // Add event listeners for keydown and keyup
    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('keyup', handleKeyUp)

    // Cleanup event listeners on unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('keyup', handleKeyUp)
    }
  }, [handleKeyDown, handleKeyUp])

  return isShiftKeyPressed
}

export default useIsShiftKeyPressed
