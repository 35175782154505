import { Temporal } from '@js-temporal/polyfill'
import { useNavigate } from 'react-router-dom'

import { ButtonGroup } from '@atlaskit/button'
import { IconButton } from '@atlaskit/button/new'

import ChevronLeftLargeIcon from '@atlaskit/icon/glyph/chevron-left-large'
import ChevronRightLargeIcon from '@atlaskit/icon/glyph/chevron-right-large'
import RefreshIcon from '@atlaskit/icon/glyph/refresh'

import { ViewMode } from '../dispatchTypes'

interface DispatchPageActionsProps {
  selectedDate: Temporal.PlainDate
  isToday: boolean
  viewMode: ViewMode
}
const DispatchPageActions = ({ selectedDate, isToday, viewMode }: DispatchPageActionsProps) => {
  const navigate = useNavigate()
  const handleBackOneDay = () => {
    navigate(`/dispatch/${viewMode}/${selectedDate.subtract({ days: 1 }).toString()}`)
  }
  const handleForwardOneDay = () => {
    navigate(`/dispatch/${viewMode}/${selectedDate.add({ days: 1 }).toString()}`)
  }
  const handleToday = () => {
    navigate(`/dispatch/${viewMode}/`)
  }
  return (
    <div>
      <ButtonGroup label='Dispatch Date'>
        {/* <RadioGroup
          options={['By Dispatch', 'By Installer', 'By Vehicle']}
          setValue={handleRadioSelect}
          value={REVERSE_LOOKUPS[viewMode]}
        /> */}
        <IconButton icon={ChevronLeftLargeIcon} label='Back One Day' isTooltipDisabled={false} onClick={handleBackOneDay} />
        <IconButton icon={RefreshIcon} label='Back to Today' isTooltipDisabled={false} onClick={handleToday} isDisabled={isToday} />
        <IconButton icon={ChevronRightLargeIcon} label='Forward One Day' isTooltipDisabled={false} onClick={handleForwardOneDay} />
      </ButtonGroup>
    </div>
  )
  // function handleRadioSelect(label: string) {
  //   const newViewMode = LOOKUPS[label as ViewModeLabel] as ViewMode
  //   console.log('handleRadioSelect label', { label, newViewMode })
  //   navigate(`/dispatch/${newViewMode}/${selectedDate.toString()}`)
  // }
}

export default DispatchPageActions

// type ViewModeLabel = 'By Dispatch' | 'By Installer' | 'By Vehicle'
// const LOOKUPS: Record<ViewModeLabel, ViewMode> = {
//   'By Dispatch': VIEW_MODE_BY_DISPATCH,
//   'By Installer': VIEW_MODE_BY_INSTALLER,
//   'By Vehicle': VIEW_MODE_BY_VEHICLE,
// }

// const REVERSE_LOOKUPS = {
//   [VIEW_MODE_BY_DISPATCH]: 'By Dispatch',
//   [VIEW_MODE_BY_INSTALLER]: 'By Installer',
//   [VIEW_MODE_BY_VEHICLE]: 'By Vehicle',
// }
