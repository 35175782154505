import { useSetAtom } from 'jotai'
import { useEffect, useRef } from 'react'
import styled from 'styled-components'

import { token } from '@atlaskit/tokens'

import useVehiclesQuery from '@/utils/queryHooks/useVehiclesQuery'

import { centerSectionWidthAtom } from '../dispatchTypes'
import VehicleContainer from './VehicleContainer'

const AvailableVehicles = ({ dateString }: { dateString: string }) => {
  const { vehicles, error, status } = useVehiclesQuery()
  const setCenterSectionWidth = useSetAtom(centerSectionWidthAtom)
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        // console.log('Width: ', containerRef.current.offsetWidth)
        setCenterSectionWidth(containerRef.current.offsetWidth)
      }
    }

    updateWidth()
    window.addEventListener('resize', updateWidth)

    return () => {
      window.removeEventListener('resize', updateWidth)
    }
  }, [setCenterSectionWidth])

  return (
    <AvailableVehiclesContainer ref={containerRef}>
      <div className='center-list-header'>
        <h4>Vehicles Available: {vehicles.length}</h4>
      </div>

      <div className='scrollable-list'>
        {!error ? null : <div>Error: {error.message}</div>}

        {status === 'pending' ? <div>Loading...</div> : null}

        {vehicles.map(vehicle => (
          <VehicleContainer dateString={dateString} key={vehicle.id} vehicle={vehicle} />
        ))}
      </div>
    </AvailableVehiclesContainer>
  )
}

export default AvailableVehicles

const AvailableVehiclesContainer = styled.div`
  position: absolute;
  inset: 0;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;

  box-sizing: border-box;

  max-width: 100%;

  .center-list-header {
    padding: 12px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid ${token('color.border')};

    background: ${token('elevation.surface')};

    h4 {
      font-weight: 500;
      color: ${token('color.text.subtle')};
    }
  }
  .scrollable-list {
    overscroll-behavior: auto;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 8px;
    flex: 1 1 auto;
    padding: 12px 24px 36px 24px;
  }
`
