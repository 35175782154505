import { useQuery } from '@tanstack/react-query'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'

import { graphql } from '@/gql'
import useGraphQLClient from '@/utils/useAuthRequest'

import {
  assignedVehicleDispatchIdsByInstallerIdAtom,
  Dispatch,
  dispatchesByIdAtom,
  dispatchIdsByTripAtom,
  dispatchIdsByVehicleIdAtom,
  IdListById,
  pendingMutationsAtom,
  sortedVehicleDispatchIdsByVehicleIdAtom,
  VehicleDispatch,
  vehicleDispatchesByIdAtom,
  vehicleDispatchIdsByVehicleIdAtom,
} from '../dispatchTypes'

export const useAvailableDispatchesCount = (): number => {
  const dispatchesByIdLengthAtom = useMemo(() => selectAtom(dispatchesByIdAtom, dispatchesById => Object.keys(dispatchesById).length), [])
  return useAtomValue(dispatchesByIdLengthAtom)
}

const useDispatchDateQuery = (dateString: string) => {
  const setDispatchIdsByTrip = useSetAtom(dispatchIdsByTripAtom)
  const setSortedVehicleDispatchIdsByVehicleId = useSetAtom(sortedVehicleDispatchIdsByVehicleIdAtom)
  const setDispatchesById = useSetAtom(dispatchesByIdAtom)
  const setVehicleDispatchIdsByVehicleId = useSetAtom(vehicleDispatchIdsByVehicleIdAtom)
  const setVehicleDispatchesById = useSetAtom(vehicleDispatchesByIdAtom)
  const setDispatchIdsByVehicleId = useSetAtom(dispatchIdsByVehicleIdAtom)
  const setAssignedVehicleDispatchIdsByInstallerId = useSetAtom(assignedVehicleDispatchIdsByInstallerIdAtom)
  const setPendingMutations = useSetAtom(pendingMutationsAtom)
  const graphQLClient = useGraphQLClient()
  const { data, error, isPending } = useQuery({
    queryKey: [GET_DISPATCH_DATE_QUERY_KEY, dateString],
    queryFn: async () => {
      const result = await graphQLClient.request(GET_DISPATCH_DATE, { date: dateString })
      if (!result || result.dispatches === undefined) throw new Error('Dispatches Database Query Failed')

      const sortedVehicleDispatchIdsByVehicleId: IdListById = result.vehicleDates.reduce(
        (acc, vehicleDate) => {
          const vehicleId = vehicleDate.vehicleId
          acc[vehicleId] = vehicleDate.sortedVehicleDispatchIds ?? []
          return acc
        },
        {} as Record<string, string[]>
      )

      const dispatchIdsByTrip: IdListById = {}
      const vehicleDispatchIdsByVehicleId: IdListById = {}
      const dispatchIdsByVehicleId: IdListById = {}
      const assignedVehicleDispatchIdsByInstallerId: IdListById = {}
      const dispatchesById: Record<string, Dispatch> = {}
      const vehicleDispatchesById: Record<string, VehicleDispatch> = {}
      if (dateString) {
        dispatchIdsByTrip.dateString = dateString
        vehicleDispatchIdsByVehicleId.dateString = dateString
        assignedVehicleDispatchIdsByInstallerId.dateString = dateString
      }

      result.dispatches.forEach(dispatch => {
        const { id, workOrder } = dispatch
        dispatchesById[id] = dispatch
        const tripName = workOrder?.tripName ?? ''
        dispatchIdsByTrip[tripName] = [...(dispatchIdsByTrip[tripName] ?? []), id]
      })

      result.vehicleDispatchAssignments.forEach(assignment => {
        const { id: vehicleDispatchId, vehicleId, dispatchId } = assignment
        vehicleDispatchIdsByVehicleId[vehicleId] = [...(vehicleDispatchIdsByVehicleId[vehicleId] ?? []), vehicleDispatchId]
        dispatchIdsByVehicleId[vehicleId] = [...(dispatchIdsByVehicleId[vehicleId] ?? []), dispatchId]
        assignment.installerAssignments.forEach(({ installerId }) => {
          assignedVehicleDispatchIdsByInstallerId[installerId] = [
            ...(assignedVehicleDispatchIdsByInstallerId[installerId] ?? []),
            vehicleDispatchId,
          ]
        })
        vehicleDispatchesById[vehicleDispatchId] = assignment
      })
      console.log({
        dispatchesById,
        dispatchIdsByTrip,
        sortedVehicleDispatchIdsByVehicleId,
        vehicleDispatchIdsByVehicleId,
        vehicleDispatchesById,
        assignedVehicleDispatchIdsByInstallerId,
      })

      setDispatchesById(dispatchesById)
      setDispatchIdsByTrip(dispatchIdsByTrip)
      setSortedVehicleDispatchIdsByVehicleId(sortedVehicleDispatchIdsByVehicleId)
      setVehicleDispatchIdsByVehicleId(vehicleDispatchIdsByVehicleId)
      setVehicleDispatchesById(vehicleDispatchesById)
      setDispatchIdsByVehicleId(dispatchIdsByVehicleId)
      setAssignedVehicleDispatchIdsByInstallerId(assignedVehicleDispatchIdsByInstallerId)
      setPendingMutations({})
      return result
    },
  })

  const dispatches = data?.dispatches ?? []

  return { dispatches, error, isPending }
}

export default useDispatchDateQuery

export const GET_DISPATCH_DATE_QUERY_KEY = 'dispatchDate'
const GET_DISPATCH_DATE = graphql(/* GraphQL */ `
  query GetDispatchDate($date: Date!) {
    vehicleDates(filters: { date: $date }) {
      id
      date
      vehicleId
      sortedVehicleDispatchIds
      isUnavailable
    }
    vehicleDispatchAssignments(filters: { dispatchDate: $date }) {
      id
      vehicleId
      dispatchId
      filterDate

      installerAssignments {
        id
        installerId
      }
    }
    dispatches(filters: { forDate: $date, confirmedOnly: true }) {
      id
      date

      vehicleAssignments {
        id
        vehicleId
      }

      workOrderId
      workOrder {
        id
        name
        status
        statusNotes
        isConfirmed
        isWorkCompleted

        materialCostTotal
        laborCostTotal
        totalPrice
        marginPercent

        tripName

        jobId
        jobTitle
        projectSiteAddress
        customerName

        estimateId
        estimateTitle
      }
    }
  }
`)
