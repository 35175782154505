import { token } from '@atlaskit/tokens'
import { motion, Transition } from 'framer-motion'
import styled from 'styled-components'

interface HoveredDropZoneProps {
  isHovered: boolean
  hoveredText: string
  hoveredTextLineTwo: string
  transition?: Transition
  fontSize?: number
  inset?: number | string
}
const HOVERED_TRANSITION = { duration: 0.15, ease: [0.0, 0.99, 0.33, 0.99] }

const HoveredDropZone = ({
  isHovered,
  hoveredText,
  hoveredTextLineTwo,
  transition = HOVERED_TRANSITION,
  fontSize = 18,
  inset = 8,
}: HoveredDropZoneProps) => {
  return (
    isHovered && (
      <HoveredDiv initial={{ opacity: 0.0 }} animate={{ opacity: 1 }} transition={transition} style={{ inset }}>
        <h3 style={{ fontSize }}>
          {hoveredText}
          {!hoveredTextLineTwo ? null : (
            <>
              <br />
              {hoveredTextLineTwo}
            </>
          )}
        </h3>
      </HoveredDiv>
    )
  )
}

export default HoveredDropZone

const HoveredDiv = styled(motion.div)`
  border: 1px solid ${token('color.border.selected')};
  border-radius: 4px;

  /* min-height: 100px; */
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;
  inset: 8px;

  transition:
    background-color 0.2s,
    opacity 0.2s;

  opacity: 0.9;
  background-color: ${token('color.background.selected')};
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px); /* Add this line for Safari support */
  z-index: 4;

  > h3 {
    font-size: 18px;
    color: ${token('color.text.brand')};
    font-weight: 700;
    text-align: center;
  }
`
