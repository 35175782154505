import Badge from '@atlaskit/badge'
import { ThemeAppearance } from '@atlaskit/badge/dist/types/types'
import React from 'react'

interface PageTabBadgeProps {
  count: number
  appearance?: ThemeAppearance
  style?: React.CSSProperties
}
const PageTabBadge = ({ count, appearance = 'primary', style }: PageTabBadgeProps) => {
  if (count === 0) return null
  return (
    <span style={{ marginLeft: 4, ...(style ?? {}) }}>
      <Badge appearance={appearance}>{count}</Badge>
    </span>
  )
}

export default PageTabBadge
