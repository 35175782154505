import { useAtomValue } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'

import { assignedVehicleDispatchIdsByInstallerIdAtom as assignmentIdsAtom } from '../dispatchTypes'

const useSelectedInstallerAssignments = (installerId: string) => {
  const assignmentsAtom = useMemo(() => selectAtom(assignmentIdsAtom, byInstallerIds => byInstallerIds[installerId]), [installerId])
  return useAtomValue(assignmentsAtom)
}

export default useSelectedInstallerAssignments
