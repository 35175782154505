import { useRef } from 'react'
import styled from 'styled-components'

import { token } from '@atlaskit/tokens'

import PickupTruckSvg from '@/components/PickupTruckSvg'
import TruckIcon from '@/components/TruckIcon'

import { MONOSPACE_FONT_FAMILY } from '@/utils/constants'
import type { Vehicle } from '@/utils/queryHooks/useVehiclesQuery'
import type { CardColorOptions } from '@/utils/utilities'

import useVehicleContainerDropZones, {
  useIsVehicleBodyHoveredByDispatch,
  useIsVehicleHeaderHoveredByInstaller,
} from '../hooks/useDropZoneVehicleContainer'
import useSelectedVehicleDispatchIds from '../hooks/useSelectedVehicleDispatchIds'

import { VEHICLE_DISPATCH_CARD_GAP } from '../dispatchConstants'

import VehicleDispatchContainer from './VehicleDispatchContainer'

interface VehicleContainerProps {
  vehicle: Vehicle
  dateString: string
}

const VehicleContainer = ({ vehicle, dateString }: VehicleContainerProps) => {
  const vehicleHeaderRef = useRef<HTMLDivElement | null>(null)
  const vehicleBodyRef = useRef<HTMLDivElement | null>(null)
  const vehicleId = vehicle.id
  const isVehicleHeaderHoveredByInstaller = useIsVehicleHeaderHoveredByInstaller(vehicle.id)

  const { isHovered: isVehicleBodyHoveredByDispatch, alreadyExists: dispatchAlreadyExists } = useIsVehicleBodyHoveredByDispatch(vehicle.id)
  const vehicleDispatchIds = useSelectedVehicleDispatchIds(vehicleId)

  const noDispatches = vehicleDispatchIds.length === 0

  useVehicleContainerDropZones({ vehicle, vehicleHeaderRef, vehicleBodyRef })

  return (
    <VehicleContainerCard
      $color={vehicle.color as CardColorOptions}
      $isVehicleHeaderHoveredByInstaller={isVehicleHeaderHoveredByInstaller}
      $noDispatches={vehicleDispatchIds.length === 0}
      $isVehicleBodyHoveredByDispatch={isVehicleBodyHoveredByDispatch}
      $dispatchAlreadyExists={dispatchAlreadyExists}
    >
      <div className='vehicle-header-section' ref={vehicleHeaderRef}>
        {isVehicleHeaderHoveredByInstaller ? (
          <div className='vehicle-header-hovered-by-installer-section'>
            <div>{noDispatches ? 'No Dispatches To Assign To' : 'Assign to all Dispatches'}</div>
          </div>
        ) : null}

        <div className='vehicle-icon'>
          {vehicle.name.includes('150') ? (
            <PickupTruckSvg color={token('color.icon.subtle')} size={32} />
          ) : (
            <TruckIcon color={token('color.icon.subtle')} size={32} />
          )}
        </div>

        <div className='vehicle-card-info'>
          <div className='vehicle-card-title'>{vehicle.name}</div>
          <div className='vehicle-card-license-plate'>{vehicle.licensePlate}</div>
        </div>
      </div>

      <div className='vehicle-body-section' ref={vehicleBodyRef}>
        {isVehicleBodyHoveredByDispatch ? (
          <div className='vehicle-body-hovered-by-dispatch-section'>
            <div>{dispatchAlreadyExists ? 'Dispatch Already Assigned' : 'Assign to Vehicle'}</div>
          </div>
        ) : null}

        {vehicleDispatchIds.length === 0 ? (
          <div className='vehicle-body-no-dispatches'>
            <h4>No Dispatches Assigned</h4>
          </div>
        ) : (
          vehicleDispatchIds.map(vehicleDispatchId => (
            <VehicleDispatchContainer
              dateString={dateString}
              key={vehicleDispatchId}
              vehicleDispatchId={vehicleDispatchId}
              vehicleId={vehicleId}
            />
          ))
        )}
      </div>
    </VehicleContainerCard>
  )
}

export default VehicleContainer

interface VehicleContainerCardProps {
  $color: CardColorOptions
  $isVehicleHeaderHoveredByInstaller: boolean
  $noDispatches: boolean
  $isVehicleBodyHoveredByDispatch: boolean
  $dispatchAlreadyExists: boolean
}

const VehicleContainerCard = styled.div<VehicleContainerCardProps>`
  border: 1px solid ${({ $color }) => (!$color ? token('color.border') : token(`color.border.accent.${$color}`))};
  border-radius: 4px;
  min-width: 500px;
  /* overflow: hidden; */

  .vehicle-header-section {
    display: flex;
    background: ${({ $color }) => (!$color ? token('elevation.surface') : token(`color.background.accent.${$color}.subtlest`))};
    border-bottom: 1px solid ${({ $color }) => (!$color ? token('color.border') : token(`color.border.accent.${$color}`))};
    align-items: center;
    position: relative;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;

    .vehicle-icon {
      padding: 4px 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
    }

    .vehicle-card-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 4px 12px 4px 0;
      z-index: 1;

      .vehicle-card-title {
        font-weight: 500;
      }

      .vehicle-card-license-plate {
        padding-right: 12px;
        font-size: 12px;
        color: ${({ $color }) => (!$color ? token('elevation.surface') : token(`color.text.accent.${$color}`))};
        font-weight: 300;
        font-family: ${MONOSPACE_FONT_FAMILY};
        opacity: 0.4;
      }
    }

    .vehicle-header-hovered-by-installer-section {
      position: absolute;
      inset: 4px;
      background: ${({ $noDispatches }) =>
        $noDispatches ? token('color.background.accent.gray.subtlest.hovered') : token('color.background.selected.hovered')};
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 500;
      color: ${({ $noDispatches }) => ($noDispatches ? token('color.text.accent.gray') : token('color.text.accent.blue'))};
      z-index: 3;
      border: 1px solid ${({ $noDispatches }) => (!$noDispatches ? token('color.border') : token('color.border.accent.gray'))};
    }
  }

  .vehicle-body-section {
    position: relative;
    background: ${token('elevation.surface.sunken')};

    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: ${VEHICLE_DISPATCH_CARD_GAP}px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;

    min-height: 60px;

    padding: ${VEHICLE_DISPATCH_CARD_GAP}px ${VEHICLE_DISPATCH_CARD_GAP + 4}px;

    overflow: hidden;

    .vehicle-body-no-dispatches {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      inset: 0;
      bottom: 10%;
      z-index: 3;

      h4 {
        font-size: 24px;
        font-weight: 600;
        color: ${token('color.text.disabled')};
        opacity: 0.6;
      }
    }
    .vehicle-body-hovered-by-dispatch-section {
      position: absolute;
      inset: 4px;
      background: ${({ $dispatchAlreadyExists }) =>
        $dispatchAlreadyExists ? token('color.background.accent.gray.subtlest.hovered') : token('color.background.selected.hovered')};
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 500;
      color: ${({ $dispatchAlreadyExists }) =>
        $dispatchAlreadyExists ? token('color.text.accent.gray') : token('color.text.accent.blue')};
      z-index: 3;
      border: 1px solid ${token('color.border.accent.gray')};
    }
  }
`
